import useModalContext from "../../hooks/contextHooks/useModalContext"
import useStyleContext from "../../hooks/contextHooks/useStyleContext"
import useLogin from "../../hooks/otherHooks/useLogin"
import { Typography, TextField, Button } from "@mui/material"
import ForgotPasswordModal from "./ForgotPasswordModal"

const LoginForm = () => {

  const {login, error} = useLogin()

  const {primaryColor} = useStyleContext()
  const {setForgotPasswordModal} = useModalContext()

  const handleSubmit = (event) => {
    event.preventDefault()
    const loginInfo = {
      email: event.target[0].value,
      password: event.target[2].value
    }
    login(loginInfo)
  }

  const forgotPasswordModal = () => {
    setForgotPasswordModal(true)
  }

  return (
    <>
      <ForgotPasswordModal />

      <Typography sx={{fontSize: "30px",  textAlign:"left", color:"#d9534f"}}>
        Log In
      </Typography>
      <hr style={{margin: "15px 0 15px 0"}} />
        {error && 
          <Typography sx={{color: "#d9534f", marginTop:"10px", fontSize:"15px"}}>
            {error}
          </Typography>
        }
        <form onSubmit={handleSubmit}>

          <TextField required fullWidth id="username" type="email" label="Email" variant="outlined" sx={{margin:"10px 0 20px", "& .MuiFormLabel-asterisk": {
          display: "none"
        }}}/>
          <TextField required fullWidth type="password" id="password" label="Password" variant="outlined" sx={{"& .MuiFormLabel-asterisk": {
          display: "none"
        }}}/>

          <Typography
            onClick = {forgotPasswordModal}
            sx={{fontSize:"12px", textAlign:"right", margin:"10px 0 20px 0", color:primaryColor, '&:hover': {cursor: "pointer", textDecoration:"underline"}}}
          >
            Forgot/reset password?
          </Typography>
          
          <div className="d-grid">
            <Button 
              type="submit" 
              size="large"
              variant="contained" 
              sx={{textTransform: "none", backgroundColor:"#d9534f", color: "white", '&:hover': {backgroundColor: "#d44a46"}}}
            >
              Continue
            </Button>
          </div>
        </form>
    </>
  )
}

export default LoginForm