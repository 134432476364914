import "./Footer.css"

const Footer = () => {

  let date = new Date().getFullYear()

  return (
    <footer>
      © {date} ZN Fashions Inc
    </footer>

  )
}

export default Footer