import useEventsContext from "../../../hooks/contextHooks/useEventsContext"
import useModalContext from "../../../hooks/contextHooks/useModalContext"

import { useState, useEffect } from "react"
import {useNavigate} from "react-router-dom"
import { Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import useStyleContext from "../../../hooks/contextHooks/useStyleContext"
import Loading from "../../../components/Loading";


const UpcomingEvents = () => {

  const navigate = useNavigate()


  const {headingStyle} = useStyleContext()

  const {fetchEventDetails, eventDetails} = useEventsContext()

  useEffect(()=> {
    fetchEventDetails()
  }, [])

  const [detailsEvent, setDetailsEvent] = useState()
  const handleDetailsEvent = (event) => {
    setDetailsEvent(event)
    navigate("/admin/eventdetails/?_id=" + event._id)
    // setDetailsEventModal(true)
  }

  const today = new Date()
  const endDate = today.setDate(today.getDate() + 6)
  
  return (
    <>
    
   
    <div className="container" >
      <Typography sx={{fontSize: "22px", marginBottom: "10px", textAlign:"left"}}>
        This Weekend
      </Typography>

      {!eventDetails ? <Loading loadingText = "Loading this weekend's events..." /> : (
        <TableContainer component={Paper} sx={{maxHeight: "500px"}}>
          <Table size="small">
            <TableHead>
              <TableRow sx={{backgroundColor: "#df4759"}}>

                <TableCell sx={headingStyle}>Event Name</TableCell>
                <TableCell sx={headingStyle}>Event Date</TableCell>
                <TableCell sx={headingStyle}>Booths Total</TableCell>
                <TableCell sx={headingStyle}>Booths Booked</TableCell>
                <TableCell sx={headingStyle} align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventDetails?.map(event => {
                const sortDate = new Date(event.sortDate)
                if (sortDate < endDate) {
                  return (
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      key={event._id}
                    >
                      <TableCell>{event.name}</TableCell>
                      <TableCell component="th" scope="row">
                        {event.date}
                      </TableCell>
                      <TableCell>{event.boothsTotal}</TableCell>
                      <TableCell>{event.boothsBooked}</TableCell>
                      <TableCell align="center">

                        <Button 
                          variant='text' 
                          size='small' 
                          sx={{color:"gray", textTransform: "none"}} 
                          onClick={() => handleDetailsEvent(event)}
                        >
                          Details
                        </Button>

                      </TableCell>
                    </TableRow>
                  )
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>



      )}

     
    </div>




    </>
  )
}

export default UpcomingEvents