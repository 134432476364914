import { useContext } from "react";
import {BookingContext} from "../../context/BookingContext";

const useBookingContext = () => {

  const context = useContext(BookingContext)

  if (!context){
    throw Error("make sure component is wrapped inside the BookContextProvider")
  }

  return context
}

export default useBookingContext