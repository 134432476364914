import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, Tooltip } from '@mui/material';
import useStyleContext from '../../../../hooks/contextHooks/useStyleContext';
import useModalContext from '../../../../hooks/contextHooks/useModalContext';
import {useState} from "react"
import CancelledDetailsModal from '../modals/CancelledDetailsModal';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Loading from '../../../../components/Loading';

const CancelledEvents = (props) => {

  const {primaryColor, headingStyle, rowStyle} = useStyleContext()
  const {setCancelledDetailsModal} = useModalContext()

  const [cancelledRequest, setCancelledRequest] = useState()

  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit"
  }

  const convertDate = (date) => {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + 1)
    const convertedDate = newDate.toLocaleDateString("en-US", options)
    return convertedDate
  }

  const openDetailsModal = (request) => {
    setCancelledRequest(request)
    setCancelledDetailsModal(true)
  }

  const toolTipText1 = "Cancelled Requests are requests you cancelled by yourself. If they were approved before you cancelled, please review the Vendor Terms tab for how funds will be handled"
  const toolTipText2 = "Great! Nothing for you to do on this page just yet."

  return (
    <>
    <CancelledDetailsModal
      request = {cancelledRequest}
    />

    <Typography sx={{fontSize: "20px",  marginBottom: "10px", textAlign:"left"}}>
      Cancelled Requests

      <Tooltip title={toolTipText1} enterTouchDelay={0}>
        <HelpOutlineIcon sx={{color:"gray", margin:"0 0 1px 2px", fontSize:"19px"}}/>
      </Tooltip>
    </Typography>

    <hr />

    {!props.requests ? <Loading loadingText = "Loading your cancelled events..."/> : (
      <> 
        {props.requests?.filter(request => request.payStatus == "Cancelled").length == 0 &&
          <div style={{textAlign:"center", margin:"10px"}}>
            <Typography fontSize="13px">
              <span style={{color:primaryColor, fontSize:"25px", fontWeight:"bold"}}>
                No cancelled requests!
              </span>
              <br />
              <Tooltip title={toolTipText2} enterTouchDelay={0}>
                <HelpOutlineIcon sx={{color:"gray", margin:"0 0 20px 0px", fontSize:"17px"}}/>
              </Tooltip>
            </Typography>
            <Typography fontSize="13px">
              For questions, please email: <span style={{color:primaryColor, fontWeight:"bold"}}>booking@znfashions.com.</span>
            </Typography>
          </div>
        }
        <TableContainer component={Paper} sx={{maxHeight: "500px"}}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow sx={{backgroundColor: primaryColor}}>
                <TableCell sx={headingStyle}>Event Name</TableCell>
                <TableCell sx={headingStyle}>Event Date</TableCell>
                <TableCell sx={headingStyle} align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {props.requests?.map(request => {
                if (request.payStatus == "Cancelled") {
                  return (
                    <TableRow
                    key={request._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={rowStyle}>
                      {request.eventName.replace("ZN Fashions ", "")}
                    </TableCell>
                    <TableCell sx={rowStyle}>{convertDate(request.sortDate)}</TableCell>
                    <TableCell align="center" sx={rowStyle}>
                      <Button variant='text' size='small' sx={{color:"gray", textTransform:"none", fontSize:"12px"}} onClick={() => openDetailsModal(request)}>
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                  )
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      
      
      </>


    )}
    
    </>
  )
}

export default CancelledEvents