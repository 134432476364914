import { Modal, Box, Button } from "@mui/material"
import useModalContext from "../../../hooks/contextHooks/useModalContext"
import useStyleContext from "../../../hooks/contextHooks/useStyleContext"

const DetailsUserModal = (props) => {

  const {modalStyle} = useStyleContext()

  const {setDetailsUserModal} = useModalContext()

  const closeModal = () => {
    setDetailsUserModal(false)
  }

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
    >

    <Box sx={modalStyle}>
  
      <h3 style={{color: "black", textAlign:"center"}}><strong>User Details</strong></h3>
      <hr/>
      {props.user ?
        Object.keys(props.user).map(x => {
          if (x != "password" && x != "__v" && x != "active"){
            return <h6 key={x}><strong>{x + ": "}</strong>{props.user[x]}</h6>
          }
        })
      :
        null
      }
      
      <Button 
        variant="outlined"
        size="large"
        onClick={closeModal} 
        sx={{marginTop: "10px", width: "100%", borderColor: "#d44a46", textTransform: "none", color: "#d44a46", '&:hover': {color: "white", backgroundColor: "#d44a46", borderColor:"#d44a46"}}}
      >
        Close
      </Button>  

    </Box>
  </Modal>
  )
}

export default DetailsUserModal