import { useContext } from "react";
import { MailChimpContext } from "../../context/MailChimpContext";

const useMailChimpContext = (props) => {

  const context = useContext(MailChimpContext)

  if (!context){
    throw Error("make sure component is wrapped inside the MailChimpContextProvider")
  }

  return context

}

export default useMailChimpContext