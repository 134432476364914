import { useState } from "react"

const useChangePassword = () => {

  const [success, setSuccess] = useState()
  const [error, setError] = useState()


  const changePassword = async (password, id, token) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/changepassword", {
      method: "POST",
      body: JSON.stringify({password, id, token}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await response.json()
    if (data.status == 200){
      setSuccess(true)
    } else {
      setError(data.error)
    }
  }


  return {changePassword, error, setError, success, setSuccess}
}

export default useChangePassword
