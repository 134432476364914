import { createContext, useReducer, useEffect, useState } from "react";
import useAuthContext from "../hooks/contextHooks/useAuthContext";

export const BookingContext = createContext()

export const BookingContextProvider = (props) => {

  const {authState} = useAuthContext()
  const [bookEvent, setBookEvent] = useState()

  const [bookingRequests, setBookingRequests] = useState()

  const [boothsAvailable, setBoothsAvailable] = useState(true)

  const getBookingRequests = async (status, userID) => {
    const request = await fetch(process.env.REACT_APP_API_URL + "/api/getbookingrequests/" + status + "/" + userID, {
      headers: {
        "Authorization": `Bearer ${authState.token}`
      }
    })
    const data = await request.json()
    if (data.status === 200){
      setBookingRequests(data.bookingRequests)
    } else {
      setBookingRequests([])
    }
  }

  const checkAvailableBooths = async (eventID) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/checkavailablebooths", {
      method: "POST",
      body: JSON.stringify({eventID}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await response.json()
    if (data.boothsAvailable > 0) {
      setBoothsAvailable(true)
      return true
    } else {
      setBoothsAvailable(false)
      return false
    }
  }

  return (
    <BookingContext.Provider
      value = {{
        bookEvent,
        setBookEvent,
        checkAvailableBooths,
        setBoothsAvailable,
        boothsAvailable,
        bookingRequests,
        getBookingRequests,
        setBookingRequests,
        

      }}
    >
      {props.children}
    </BookingContext.Provider>

)
}
