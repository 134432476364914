import { Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import useEventsContext from "../../../hooks/contextHooks/useEventsContext"
import useModalContext from "../../../hooks/contextHooks/useModalContext"
import { useState, useEffect } from "react"
import {useNavigate} from "react-router-dom"
import useStyleContext from '../../../hooks/contextHooks/useStyleContext';
import useBookingContext from '../../../hooks/contextHooks/useBookingContext';

const Cancelled = (props) => {

  const {primaryColor, headingStyle} = useStyleContext()
  const {bookingRequests} = useBookingContext()

  const [filteredRequests, setFilteredRequests] = useState()

  useEffect(() => {
    setFilteredRequests(bookingRequests?.filter(request => request.payStatus == "Cancelled" && request.eventID == props?.eventID))
  }, [bookingRequests])

  return (
    <>
    <Typography sx={{fontSize: "22px",  marginBottom: "10px", textAlign:"left"}}>
      Cancelled Requests
    </Typography>

    <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow sx={{backgroundColor: primaryColor}}>
              <TableCell sx={headingStyle}>Name</TableCell>
              <TableCell sx={headingStyle}>Company</TableCell>
              <TableCell sx={headingStyle}>Pay Date</TableCell>
              <TableCell sx={headingStyle}>Cancel Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRequests?.map(request => {
              return (
                <TableRow
                key={request._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {request.userFirstName + " " + request.userLastName}
                </TableCell>
                <TableCell>{request.userCompanyName}</TableCell>
                <TableCell>{request.payDate}</TableCell>
                <TableCell>{request.cancelDate}</TableCell>
              </TableRow>
              )
            })}
          
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Cancelled