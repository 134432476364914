import { useState } from "react"
import useAuthContext from "../../contextHooks/useAuthContext"
import useBookingContext from "../../contextHooks/useBookingContext"

const useUpdateRequest = () => {

  const {authState} = useAuthContext()
  const {setBookingRequests} = useBookingContext()

  const [error, setError] = useState()
  const [success, setSuccess] = useState()

  const updateRequest = async (id, updates) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/updaterequest", {
      method: "POST",
      body: JSON.stringify({id, ...updates, userID: authState.id}),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authState.token}`
      }
    })
    const data = await response.json()
    if (data.status === 200){
      setSuccess(true)
      setBookingRequests(data.updatedRequests)
    } else {
      setError(data.error)
    }
  }


  return {updateRequest, error, setError, success, setSuccess}
}

export default useUpdateRequest