import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import {
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { useEffect } from 'react';

import useUsersContext from '../../../hooks/contextHooks/useUsersContext';
import useStyleContext from '../../../hooks/contextHooks/useStyleContext';
import useBookingContext from '../../../hooks/contextHooks/useBookingContext';


export default () => {

  const {headingStyle} = useStyleContext()
  
  const {getBookingRequests, bookingRequests} = useBookingContext()

  useEffect(() => {
    getBookingRequests()
  }, [])

  const [columns] = useState([
    { name: 'userFirstName', title: 'First Name' },
    { name: 'userLastName', title: 'Last Name' },
    { name: 'userCompanyName', title: 'Company' },
    { name: 'eventName', title: 'Event' },
    { name: 'eventDate', title: 'Date' },
    { name: 'bookDate', title: 'Submitted' },
    { name: 'payStatus', title: 'Status' },
  ]);

  const [searchValue, setSearchState] = useState('');

  return (
    <Paper>
      <Grid
        rows={bookingRequests ? bookingRequests : []}
        columns={columns}
      >
        <SearchState
          value={searchValue}
          onValueChange={setSearchState}
        />
        <IntegratedFiltering />
        <Table />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel/>
      </Grid>
    </Paper>
  );
};
