import Footer from "../global/footer/Footer"
import NavBar from "../global/navbar/NavBar"
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import ResetPasswordForm from "./ResetPasswordForm"

const ResetPassword = () => {
  const navigate = useNavigate()
  return (
    <>
      <NavBar
        admin = {true}
      />

      <div className="container" style={{
        width: "320px",
        margin: "150px auto 60px auto",
      }}>
        <div className="row justify-content-md-center">
          <div className="col text-center">
            <ResetPasswordForm/>
          </div>
        </div>
      </div>

      <Footer/>
    </>
  )
}

export default ResetPassword