import { Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal, Box } from '@mui/material';
import { useState, useEffect } from "react"
import useStyleContext from '../../../hooks/contextHooks/useStyleContext';
import useBookingContext from '../../../hooks/contextHooks/useBookingContext';
import useModalContext from '../../../hooks/contextHooks/useModalContext';
import ModalCloseButton from '../../../components/ModalCloseButton';

const Approved = (props) => {

  const {primaryColor, headingStyle, modalStyle} = useStyleContext()
  const [approvedRequests, setApprovedRequests] = useState()
  const {bookingRequests} = useBookingContext()

  useEffect(() => {
    setApprovedRequests(bookingRequests?.filter(request => request.payStatus == "Approved" && request.eventID == props?.eventID))
  }, [bookingRequests])

  const [booking, setBooking] = useState()
  const [image, setImage] = useState()

  const {
    setViewImageModal,
    viewImageModal
  } = useModalContext()

  const openViewImageModal = (image) => {
    setImage(image)
    setViewImageModal(true)
  }

  const closeModal = () => {
    setBooking(null)
    setViewImageModal(false)
  }


  return (
    <>

    <Modal
      open={viewImageModal}
      onClose={closeModal}
      >
      <Box sx={modalStyle}>
        <Box textAlign="center" marginBottom="10px">
          <Typography sx={{fontSize: "25px", color: primaryColor}}>
            Payment Confirmation
          </Typography>
          <hr />
          <img src={image} height="400px" style={{marginTop:"10px"}}/>
        </Box>    
        <ModalCloseButton
          onClick = {closeModal}
        />
      </Box>
    </Modal>
      
    <Typography sx={{fontSize: "22px",  marginBottom: "10px", textAlign:"left"}}>
      Approved Requests
    </Typography>

    <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow sx={{backgroundColor: primaryColor}}>
              <TableCell sx={headingStyle}>#</TableCell>
              <TableCell sx={headingStyle}>Name</TableCell>
              <TableCell sx={headingStyle}>Company</TableCell>
              <TableCell sx={headingStyle}>Pay Date</TableCell>
              <TableCell sx={headingStyle}>Pay Amount</TableCell>
              <TableCell sx={headingStyle}>Pay Conf</TableCell>
              <TableCell sx={headingStyle}>Approve Date</TableCell>
              <TableCell sx={headingStyle} align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {approvedRequests?.map((request, index) => {
              return (
                <TableRow
                key={request._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>{request.userFirstName + " " + request.userLastName}</TableCell>
                  <TableCell>{request.userCompanyName}</TableCell>
                  <TableCell>{request.payDate}</TableCell>
                  <TableCell>{request.payAmount}</TableCell>
                  <TableCell align="center">
                    {request.payConfirmation.length > 1000 ? (
                      <Button 
                        variant="text"
                        onClick={() => openViewImageModal(request.payConfirmation)}
                        sx={{textTransform:"none", color:primaryColor}}
                      >
                        View
                      </Button>
                    ) : (
                      request.payConfirmation
                    )}
                  </TableCell>
                  <TableCell>{request.approveDate}</TableCell>
                  <TableCell align="center">
                    <Button 
                      variant='text'
                      size='small' 
                      sx={{color:"red", textTransform:"none"}} 
                      onClick={() => props.deny(request)}
                    >
                      Deny
                    </Button>
                  </TableCell>
              </TableRow>
              )
            })}
          
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Approved