import { useState } from "react"
import useBookingContext from "../../contextHooks/useBookingContext"
import useAuthContext from "../../contextHooks/useAuthContext"

const useApproveRequest = () => {

  const {authState} = useAuthContext()
  const {setBookingRequests, getBookingRequests} = useBookingContext()

  const [success, setSuccess] = useState()
  const [error, setError] = useState()

  const approveRequest = async (booking) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/approvebookingrequest", {
      method: "POST",
      body: JSON.stringify(booking),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authState.token}`
      }
    })
    const data = await response.json()
    if (data.status === 200){
      getBookingRequests("Pending Review")
      setSuccess(true)
    } else {
      setError(data.error)
    }
  }

  return {approveRequest, error, success, setError, setSuccess}

}

export default useApproveRequest