import { useState } from "react"
import useAuthContext from "../../contextHooks/useAuthContext"
import useBookingContext from "../../contextHooks/useBookingContext"
import useEventsContext from "../../contextHooks/useEventsContext"

const useCancelRequest = () => {
  
  const {authState} = useAuthContext()
  const {setBookingRequests} = useBookingContext()
  const {getUnbookedEvents} = useEventsContext()

  const [error, setError] = useState()
  const [success, setSuccess] = useState()

  const cancelRequest = async (request, currentStatus) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/cancelbookingrequest", {
      method: "POST",
      body: JSON.stringify({request, currentStatus}),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authState.token}`
      }
    })
    const data = await response.json()
    if (data.status === 200){
      setSuccess(true)
      setBookingRequests(data.updatedRequests)
    } else {
      setError(data.error)
    }
  }

  return {cancelRequest, error, setError, success, setSuccess}
}

export default useCancelRequest