import { Box, Button, Slide, Typography } from "@mui/material"
import NavBar from "../global/navbar/NavBar"
import useGetUser from "../../hooks/otherHooks/user-hooks/useGetUser"
import { useEffect, useState } from "react"
import useAuthContext from "../../hooks/contextHooks/useAuthContext"
import useStyleContext from "../../hooks/contextHooks/useStyleContext"
import ProfileField from "./ProfileField"
import useUpdateUser from "../../hooks/otherHooks/user-hooks/useUpdateUser"

const ProfilePage = (props) => {

  const {updateUser} = useUpdateUser()
  const {authState} = useAuthContext()
  const {primaryColor} = useStyleContext()
  const {user, getUser} = useGetUser()

  useEffect(() => {
    getUser(authState?.id)
  }, [])

  const [editEmail, setEditEmail] = useState(false)
  const [editFirstName, setEditFirstName] = useState(false)
  const [editLastName, setEditLastName] = useState(false)
  const [editCompanyName, setEditCompanyName] = useState(false)
  const [editPhone, setEditPhone] = useState(false)
  const [editAddress, setEditAddress] = useState(false)
  const [editCity, setEditCity] = useState(false)
  const [editState, setEditState] = useState(false)
  const [editCountry, setEditCountry] = useState(false)
  
  const setEditMode = (field) => {
    
    if (field == "email"){
      setEditEmail(!editEmail)
    } else if (field == "fName") {
      setEditFirstName(!editFirstName)
    } else if (field == "lName") {
      setEditLastName(!editLastName)
    } else if (field == "companyName") {
      setEditCompanyName(!editCompanyName)
    } else if (field == "phone") {
      setEditPhone(!editPhone)
    } else if (field == "address") {
      setEditAddress(!editAddress)
    } else if (field == "city") {
      setEditCity(!editCity)
    } else if (field == "state") {
      setEditState(!editState)
    } else if (field == "country") {
      setEditCountry(!editCountry)
    }

  }

  const formatDate = (input) => {
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit"
    }
    const date = new Date(input)
    const formattedDate = date.toLocaleDateString("en-US", options)
    return formattedDate
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const update = {
      userID: authState?.id,
      fieldName: e.target[0].id,
      fieldValue: e.target[0].value
    }

    await updateUser(update)

    getUser(authState?.id)

    setEditEmail(false)
    setEditFirstName(false)
    setEditLastName(false)
    setEditCompanyName(false)
    setEditPhone(false)
    setEditAddress(false)
    setEditCity(false)
    setEditState(false)
    setEditCountry(false)

  }

  return (
    <>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit {...{ timeout: 500 }}>
        <Box>
          <hr />
          <div className="container" style={{textAlign:"left"}}>

            <div className="row">
              <div className="col-3">
                <Typography>
                  Email
                </Typography>
              </div>
                <div className="col-6">
                  <Typography>
                    {user?.email}
                  </Typography>
                </div>

                {/* <ProfileField 
                  email = {true}
                  id = "email"
                  state = {editEmail}
                  userProperty = {user?.email}
                  setEditMode = {() => setEditMode("email")}
                  onSubmit = {onSubmit}
                /> */}
            </div>
            <hr/>

            <div className="row">
              <div className="col-3">
                <Typography>
                  First Name
                </Typography>
              </div>
                <div className="col-6">
                  <Typography>
                    {user?.fName}
                  </Typography>
                </div>
                {/* <ProfileField 
                  id = "fName"
                  state = {editFirstName}
                  userProperty = {user?.fName}
                  setEditMode = {() => setEditMode("fName")}
                  onSubmit = {onSubmit}
                /> */}
            </div>
            <hr/>

            <div className="row">
              <div className="col-3">
                <Typography>
                  Last Name
                </Typography>
              </div>
              <div className="col-6">
                <Typography>
                  {user?.lName}
                </Typography>
              </div>

                {/* <ProfileField 
                  id = "lName"
                  state = {editLastName}
                  userProperty = {user?.lName}
                  setEditMode = {() => setEditMode("lName")}
                  onSubmit = {onSubmit}
                /> */}
            </div>
            <hr/>

            <div className="row">
              <div className="col-3">
                <Typography>
                  Company Name
                </Typography>
              </div>
                <div className="col-6">
                  <Typography>
                    {user?.companyName}
                  </Typography>
                </div>
              {/* <ProfileField 
                  id = "companyName"
                  state = {editCompanyName}
                  userProperty = {user?.companyName}
                  setEditMode = {() => setEditMode("companyName")}
                  onSubmit = {onSubmit}
                /> */}
            </div>
            <hr/>

            <div className="row">
              <div className="col-3">
                <Typography>
                  Phone Number
                </Typography>
              </div>
                <ProfileField 
                  id = "phone"
                  state = {editPhone}
                  userProperty = {user?.phone}
                  setEditMode = {() => setEditMode("phone")}
                  onSubmit = {onSubmit}
                />
            </div>
            <hr/>

            <div className="row">
              <div className="col-3">
                <Typography>
                  Address
                </Typography>
              </div>
                <ProfileField 
                  id = "address"
                  state = {editAddress}
                  userProperty = {user?.address}
                  setEditMode = {() => setEditMode("address")}
                  onSubmit = {onSubmit}
                />
            </div>
            <hr/>

            <div className="row">
              <div className="col-3">
                <Typography>
                  City
                </Typography>
              </div>
              <ProfileField 
                  id = "city"
                  state = {editCity}
                  userProperty = {user?.city}
                  setEditMode = {() => setEditMode("city")}
                  onSubmit = {onSubmit}
                />
            </div>
            <hr/>

            <div className="row">
              <div className="col-3">
                <Typography>
                  State
                </Typography>
              </div>
              <ProfileField 
                id = "state"
                state = {editState}
                userProperty = {user?.state}
                setEditMode = {() => setEditMode("state")}
                onSubmit = {onSubmit}
              />
            </div>
            <hr/>

            <div className="row">
              <div className="col-3">
                <Typography>
                  Country
                </Typography>
              </div>
              <ProfileField 
                id = "country"
                state = {editCountry}
                userProperty = {user?.country}
                setEditMode = {() => setEditMode("country")}
                onSubmit = {onSubmit}
              />
            </div>
            <hr/>

            <div className="row">
              <div className="col-3">
                <Typography>
                  Account Created
                </Typography>
              </div>
              <div className="col-6">
                <Typography>
                  {formatDate(user?.createdAt)}
                </Typography>
              </div>
            </div>
            <hr/>

            <div className="row">
              <div className="col-3">
                <Typography>
                  Terms Agreed
                </Typography>
              </div>
              <div className="col-6">
                <Typography>
                  {user?.agreedTerms == true ? "True" : "False"}
                </Typography>
              </div>
            </div>
            <hr/>



          </div>
        </Box>
      </Slide>
      
    
    
    </>
  )
}

export default ProfilePage