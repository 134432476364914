import { Modal, Box, Typography, Button } from "@mui/material"
import ModalCloseButton from "../../../../components/ModalCloseButton"

import useModalContext from "../../../../hooks/contextHooks/useModalContext"
import useStyleContext from "../../../../hooks/contextHooks/useStyleContext"


const ReviewBookingModal = (props) => {

  const {modalStyle, primaryColor} = useStyleContext()
  const {reviewBookingModal, setReviewBookingModal} = useModalContext()

  const closeModal = () => {
    setReviewBookingModal(false)
  }

  return(
    <Modal
      open={reviewBookingModal}
      onClose={closeModal}
    >
      <Box sx={modalStyle}>

        <h3 style={{color: "black", textAlign:"center"}}><strong>Request Details</strong></h3>
        
        <hr/>

        {props.booking ?
          Object.keys(props.booking).map(x => {
            if (x != "_id" && x != "__v" && x != "userID" && x != "eventID" && x != "payConfirmation"){
              return <h6 key={x}><strong>{x + ": "}</strong>{props.booking[x]}</h6>
            }
          })
        :
          null
        }

        <ModalCloseButton
          onClick = {closeModal}
        />
        
      </Box>
    </Modal>

  )
}

export default ReviewBookingModal