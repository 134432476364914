import useAuthContext from "../../../hooks/contextHooks/useAuthContext";
import "./NavBar.css"
import logo from "./ZN Font.png"
import AccountMenu from "./AccountMenu";
import { useNavigate } from "react-router-dom";

const NavBar = (props) => {

  const {authState, authDispatch} = useAuthContext()
  const navigate = useNavigate()

  const handleLogout = () => {
    localStorage.removeItem("user")
    authDispatch({type:"LOGOUT"})
    navigate("/login")
  }

  return (
    <div className="container-fluid">

      <nav className="navbar navbar-expand-lg navbar-light fixed-top">
        <a className="navbar-brand" href={props.admin ? "/" : "#"}>
          <img src={logo} width="200px" alt=""/>
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav ms-auto text-center">
            
            <li className="nav-item">
                <a className="nav-link" href={props.admin ? "/" : "#home"}>Home</a>
            </li>

            {props.admin && authState ?
              <>
              <li style={{margin:"auto"}}>
                <AccountMenu/>
              </li>
              </>
            : null}

            {!props.admin &&
              <>
                <li className="nav-item">
                  <a className="nav-link" href="#signup">Event Alerts</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#upcoming-events">Upcoming Events</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#about-us">About Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contact-us">Contact Us</a>
                </li> 

                {authState ? 
                  <>
                    <li className="nav-item">
                      <a className="nav-link" href="/login">My Dashboard</a>
                    </li> 
                    <li className="nav-item" onClick={handleLogout}>
                      <a className="nav-link" href="#contact-us">Logout</a>
                    </li> 
                  </>
                : 
                <li className="nav-item">
                  <a className="nav-link" href="/login">Login</a>
                </li>
                }     
              </>
            }
          </ul>
        </div>
      </nav>
  </div>
  )

}

export default NavBar