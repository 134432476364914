import Footer from "../global/footer/Footer"
import NavBar from "../global/navbar/NavBar"
import LoginForm from "./LoginForm"
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"

const Login = () => {
  const navigate = useNavigate()
  return (
    <>
      <NavBar
        admin = {true}
      />

      <div className="container" style={{
        width: "320px",
        margin: "150px auto 60px auto",
      }}>
        <div className="row justify-content-md-center">
          <div className="col text-center">
            <LoginForm/>

            <div className="d-grid">
              <Button
                variant="outlined" 
                size="large"
                sx={{textTransform: "none", marginTop: "15px", color: "#d44a46", borderColor: "#d44a46", '&:hover': {backgroundColor: "#d44a46", borderColor:"#d44a46", color:"white"}}}
                onClick={() => navigate("/register")}
              >
                Register
              </Button>
            </div>

          </div>
        </div>
      </div>


      <Footer/>
    </>
  )
}

export default Login