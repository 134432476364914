import useModalContext from "../../hooks/contextHooks/useModalContext"
import useStyleContext from "../../hooks/contextHooks/useStyleContext"
import { Typography, TextField, Button, Box } from "@mui/material"
import ForgotPasswordModal from "./ForgotPasswordModal"
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

import { useNavigate, useSearchParams } from 'react-router-dom';

import {useFormik} from "formik"
import * as yup from "yup"
import useChangePassword from "../../hooks/otherHooks/useChangePassword"
import ModalCloseButton from "../../components/ModalCloseButton";
import ModalPrimaryButton from "../../components/ModalPrimaryButton";

const fieldStyling = {
  margin: "0 0 10px 0",
  width:"100%"
}


const ResetPasswordForm = () => {

  const navigate = useNavigate()

  const {changePassword, error, success} = useChangePassword()

  const {primaryColor} = useStyleContext()
  const {setForgotPasswordModal} = useModalContext()
  const [searchParams] = useSearchParams();


  const onSubmit = async (values) => {
    const id = searchParams.get("_id")
    const token = searchParams.get("token")
    const password = values.password
    changePassword(password, id, token)
  }

  const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

  const validationSchema = yup.object({
    password: yup
      .string()
      .matches(PASSWORD_REGEX, "Please enter a stronger password (must include one of each: uppercase, lowercase, special character, number)")
      .required("This field is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  })

  const formik = useFormik(
    {
      initialValues: {
        password: "",
        confirmPassword: "",
      },
    validationSchema: validationSchema,
    validateOnBlur: true,
    onSubmit
    }
  )

  return (
    <>

      {success ? 
        <Box textAlign="center">
          <h4>Password changed!</h4>
          <CheckBoxRoundedIcon sx={{color: "green", fontSize: "200px", marginBottom: "10px"}} />
          <ModalPrimaryButton
            buttonText = "Back to Login" 
            onClick = {() => navigate("/login")}
          />
        </Box>
      :
        <>
        <Typography sx={{fontSize: "30px",  textAlign:"left", color:"#d9534f"}}>
          Reset Password
        </Typography>
        <hr style={{margin: "15px 0 15px 0"}} />
          {error && 
            <Typography sx={{color: "#d9534f", marginTop:"10px", marginBottom:"10px", fontSize:"13px"}}>
              {error}
            </Typography>
          }
          <form onSubmit={formik.handleSubmit}>

            <TextField 
              name = "password" 
              value = {formik.values.password} 
              onChange = {formik.handleChange}
              onBlur = {formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              fullWidth variant="outlined" label="New Password" type="password" sx={{margin:"10px 0 20px"}}/>  

            <TextField 
              name = "confirmPassword" 
              value = {formik.values.confirmPassword} 
              onChange = {formik.handleChange}
              onBlur = {formik.handleBlur}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              fullWidth variant="outlined" label="Confirm New Password" type="password" /> 
              
              <div className="d-grid">
              <Button 
                type="submit" 
                size="large"
                variant="contained" 
                sx={{marginTop:"15px", textTransform: "none", backgroundColor:"#d9534f", color: "white", '&:hover': {backgroundColor: "#d44a46"}}}
              >
                Reset Password
              </Button>
            </div>
          </form>
        </>
      }
    </>
  )
}

export default ResetPasswordForm