import useModalContext from "../../../hooks/contextHooks/useModalContext"
import { Modal, Box, Button, Typography} from "@mui/material"
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import AddUserForm from "./AddUserForm";
import { useState } from "react";
import useStyleContext from "../../../hooks/contextHooks/useStyleContext";

const AddUserModal = (props) => {

  const {modalStyle} = useStyleContext()
  
  const {setAddUserModal} = useModalContext()
  const [success2, setSuccess2] = useState(false)

  const closeModal = () => {
    setAddUserModal(false)
    setSuccess2(false)
  }

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
    >

      <Box sx={modalStyle}>
        {success2 ? 
          <Box textAlign="center">
            <h4>Successfully Added User!</h4>
            <CheckBoxRoundedIcon sx={{color: "green", fontSize: "200px", marginBottom: "10px"}} />
            <button 
              onClick={closeModal} 
              className="btn btn-danger" 
              style={{width:"100%"}}
            >
              Close
            </button>
          </Box>
        :
          <>
            <h3 style={{color: "black", textAlign:"center"}}><strong>Add User</strong></h3>
            <hr/>
            <AddUserForm
              setSuccess2 = {setSuccess2}
            />
            <Button 
              variant="outlined"
              size="large"
              onClick={closeModal} 
              sx={{marginTop: "10px", width: "100%", borderColor: "#d44a46", textTransform: "none", color: "#d44a46", '&:hover': {color: "white", backgroundColor: "#d44a46", borderColor:"#d44a46"}}}
            >
              Close
            </Button>  
          </>
        }
      </Box>
    </Modal>
  );
}

export default AddUserModal