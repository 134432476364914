import { Modal, Box, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import useModalContext from "../../../../hooks/contextHooks/useModalContext"
import useStyleContext from "../../../../hooks/contextHooks/useStyleContext"
import ModalCloseButton from "../../../../components/ModalCloseButton"
import useUsersContext from "../../../../hooks/contextHooks/useUsersContext"
import useEventsContext from "../../../../hooks/contextHooks/useEventsContext"
import ModalPrimaryButton from "../../../../components/ModalPrimaryButton"
import useAuthContext from "../../../../hooks/contextHooks/useAuthContext"
import useSubmitRequest from "../../../../hooks/otherHooks/booking-hooks/useSubmitRequest"

const AddBookingModal = () => {
  
  const {authState} = useAuthContext()
  const {modalStyle, primaryColor} = useStyleContext()
  const {addBookingModal, setAddBookingModal} = useModalContext()
  const {users} = useUsersContext()
  const {eventDetails} = useEventsContext()

  const {checkAvailableBooths} = useSubmitRequest()


  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageBinary, setImageBinary] = useState(null)
  const [imageError, setImageError] = useState("")

  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setImageBinary(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
 }

  useEffect(() => {
    if (selectedImage) {
      getBase64(selectedImage)
      setImageUrl(URL.createObjectURL(selectedImage));
      setImageError("")
    }
  }, [selectedImage]);


  const handleSubmit = async (e) => {
    e.preventDefault()

    const bookEvent = e.target[2].value

    const available = await checkAvailableBooths(bookEvent?._id)
    
    if (available){

      const options = {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit"
      }

      const date = new Date()
      const payDateUnformatted = new Date(e.target[2].value)

      const bookingInfo = {
        bookBy: authState,
        bookDate: date.toLocaleDateString("en-US", options),
        bookEvent: bookEvent,
        payMethod: e.target[0].value,
        payAmount: e.target[1].value,
        payDate: payDateUnformatted.toLocaleDateString("en-US", options),
        payConfirmation: imageBinary,
      }

      console.log(bookingInfo)
    }
  }

  const closeModal = () => {
    setAddBookingModal(false)
  }

  return (
    <Modal
      open={addBookingModal}
      onClose={closeModal}
    >
      <Box sx={modalStyle}>
        <form onSubmit={handleSubmit}>

        <Typography sx={{fontSize: "25px", color: primaryColor}}>
          Add Booking
        </Typography>

        <hr />

        <Typography sx={{fontSize: "15px", margin:"0 0 0 3px", color:"#d9534f"}}>
          Payment Method
        </Typography>

        <select 
          className="form-select mb-3" 
          name="payMethod" 
          defaultValue="Select"
          required
        >
          <option value="Zelle">Zelle</option>
          <option value="Venmo">Venmo</option>
          <option value="Direct Deposit">Direct Deposit</option>
          <option value="Cash">Cash</option>
        </select>

          <Typography sx={{fontSize: "15px", margin:"0 0 0 3px", color:"#d9534f"}}>
            Vendor
          </Typography>
          <select 
            className="form-select mb-3" 
            name="vendor" 
            defaultValue="Select"
            required
          >
            {users?.map(user => {
              return (
                <option key={user._id} value={user._id}>{user.email}</option>
              )
            })}

          </select>

          <Typography sx={{fontSize: "15px", margin:"0 0 0 3px", color:"#d9534f"}}>
            Event
          </Typography>
          <select 
            className="form-select mb-3" 
            name="event" 
            defaultValue="Select"
            required
          >
            {eventDetails?.map(event => {
              return (
                <option key={event._id} value={event._id}>{event.name}</option>
              )
            })}

          </select>

          <div className="row g-2">
            <div className="col-6">
              <Typography sx={{fontSize: "15px", margin:"10px 0 0 3px", color:"#d9534f"}}>
                Payment Amount
              </Typography>
              <input 
                name="payAmount"
                type="number" 
                className="form-control"  
                required
              />
            </div>
            <div className="col-6">
              <Typography sx={{fontSize: "15px", margin:"10px 0 0 3px", color:"#d9534f"}}>
                Payment Date
              </Typography>
              <input 
                name="payDate"
                type="date" 
                className="form-control"  
                required
              />
            </div>
          </div>

          <Typography sx={{fontSize: "15px", margin:"10px 0 0 3px", color:"#d9534f"}}>
            Payment Confirmation Number
          </Typography>
          <input 
            name="payNumber"
            type="input" 
            className="form-control"  
            style={{marginBottom:"10px"}}
            required
          />

                  
          <ModalPrimaryButton 
            type="submit"
            onClick = {handleSubmit}
            buttonText = "Add"
          />

        </form>


        <ModalCloseButton
          onClick = {closeModal}
        />
      </Box>
    </Modal>
  )

}

export default AddBookingModal