import {useEffect, useState} from "react"
import PropTypes from 'prop-types';
import { Typography, Button, Slide, Box, Grow, Paper, Tab, Tabs } from '@mui/material';
import useBookingContext from '../../../hooks/contextHooks/useBookingContext';
import useModalContext from '../../../hooks/contextHooks/useModalContext';
import BookingModal from '../../admin/booking-modal/BookingModal';
import useStyleContext from '../../../hooks/contextHooks/useStyleContext';
import useEventsContext from '../../../hooks/contextHooks/useEventsContext';
import useAuthContext from "../../../hooks/contextHooks/useAuthContext";
import { styled } from '@mui/material/styles';
import AvailableEvents from "./AvailableEvents";
import SoldOutEvents from "./SoldOutEvents";

const TabPanel = (props) => {
  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding:"30px 0" }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const BookEvent = () => {

  const {authState} = useAuthContext()
  const {primaryColor} = useStyleContext()
  const [value, setValue] = useState(0);

  const {bookingModal} = useModalContext()
  const {getUnbookedEvents, unbookedEvents} = useEventsContext()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const AntTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent'
    },
    '& .MuiTabs-indicatorSpan': {
      width: '100%',
      backgroundColor: primaryColor,
    },
  });

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    minWidth: 0,
    fontSize:"13px",
    width:"20px",
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    '&:hover': {
      color: primaryColor,
      opacity: 1,
    },
    '&.Mui-selected': {
      color: primaryColor,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: primaryColor,
    },
  }));

  useEffect(() => {
    getUnbookedEvents()
  }, [bookingModal])

  return (
    <>
    <Box sx={{ width: '100%'}}>
      
      <Slide direction="left" in={true} mountOnEnter unmountOnExit {...{ timeout: 600 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <AntTabs value={value} variant='fullWidth' onChange={handleChange} >
            <AntTab label="Available Events" {...a11yProps(0)}/>
            <AntTab label="Sold Out Events" {...a11yProps(1)} />
          </AntTabs>
        </Box>
      </Slide>

      <Slide direction="up" in={true} mountOnEnter unmountOnExit {...{ timeout: 600 }}>
        <Box>
          <TabPanel value={value} index={0}>
            <AvailableEvents unbookedEvents = {unbookedEvents}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SoldOutEvents unbookedEvents = {unbookedEvents}/>
          </TabPanel>
        </Box>
      </Slide>

    </Box>
      
 
    </>
  )
}

export default BookEvent