import {Typography, TextField, Button} from "@mui/material"
import {useFormik} from "formik"
import * as yup from "yup"
import useAddUser from "../../../hooks/otherHooks/user-hooks/useAddUser";

const fieldStyling = {
  margin: "0 0 10px 0",
  width:"100%"
}

const AddUserForm = (props) => {

  const {addUser, error, success} = useAddUser()

  const onSubmit = (values) => {
    addUser(values)
    props.setSuccess2(success)
  }

  const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

  const validationSchema = yup.object({
    fName: yup
      .string()
      .min(3,"Please enter a valid first name")
      .required("This field is required"),
    lName: yup
      .string()
      .min(3,"Please enter a valid first name")
      .required("This field is required"),
    companyName: yup
      .string()
      .min(4, "Please enter a valid state")
      .required("This field is required"),
    phone: yup
      .string()
      .min(10, "Please enter a valid 10-digit phone number")
      .max(10, "Please enter a valid 10-digit phone number")
      .required("This field is required"),
    address: yup
      .string()
      .min(5, "Please enter a valid address")
      .required("This field is required"),
    city: yup
      .string()
      .min(5, "Please enter a valid city")
      .required("This field is required"),
    state: yup
      .string()
      .min(4, "Please enter a valid state")
      .required("This field is required"),
    zip: yup
      .string()
      .min(5, "Please enter a valid 5-digit zip code")
      .max(5, "Please enter a valid 5-digit zip code")
      .required("This field is required"),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("This field is required"),
    password: yup
      .string()
      .matches(PASSWORD_REGEX, "Please enter a stronger password (must include one of each: uppercase, lowercase, special character, number)")
      .required("This field is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  })

  const formik = useFormik(
    {
      initialValues: {
        fName: "",
        lName: "",
        companyName: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        email: "",
        password: "",
        confirmPassword: ""
      },
    validationSchema: validationSchema,
    validateOnBlur: true,
    onSubmit
    }
  )

  return (
    <form onSubmit={formik.handleSubmit}>
          
    <Typography sx={{textAlign: "left", margin: "10px 0 10px 3px"}}>
      User Info
    </Typography>
  
    <div className="row g-2">
      <div className="col">
        <TextField 
          name = "fName" 
          value = {formik.values.fName} 
          onChange = {formik.handleChange}
          onBlur = {formik.handleBlur}
          error={formik.touched.fName && Boolean(formik.errors.fName)}
          helperText={formik.touched.fName && formik.errors.fName}
          fullWidth variant="outlined" label="First Name" sx={fieldStyling}
        />
      </div>
      <div className="col">
        <TextField 
          name = "lName" 
          value = {formik.values.lName} 
          onChange = {formik.handleChange}
          onBlur = {formik.handleBlur}
          error={formik.touched.lName && Boolean(formik.errors.lName)}
          helperText={formik.touched.lName && formik.errors.lName}
          fullWidth variant="outlined" label="Last Name" sx={fieldStyling}
        />
      </div>
    </div>

    <TextField 
      name = "companyName" 
      value = {formik.values.companyName} 
      onChange = {formik.handleChange}
      onBlur = {formik.handleBlur}
      error={formik.touched.companyName && Boolean(formik.errors.companyName)}
      helperText={formik.touched.companyName && formik.errors.companyName}
      fullWidth variant="outlined" label="Company Name" sx={fieldStyling}
    />

    <TextField 
      name = "phone" 
      value = {formik.values.phone} 
      onChange = {formik.handleChange}
      onBlur = {formik.handleBlur}
      error={formik.touched.phone && Boolean(formik.errors.phone)}
      helperText={formik.touched.phone && formik.errors.phone}
      fullWidth variant="outlined" label="Cell Phone" sx={fieldStyling}
    />

    <TextField 
      name = "address" 
      value = {formik.values.address} 
      onChange = {formik.handleChange}
      onBlur = {formik.handleBlur}
      error={formik.touched.address && Boolean(formik.errors.address)}
      helperText={formik.touched.address && formik.errors.address}
      fullWidth variant="outlined" label="Address" sx={fieldStyling}
    />

  <TextField 
      name = "city" 
      value = {formik.values.city} 
      onChange = {formik.handleChange}
      onBlur = {formik.handleBlur}
      error={formik.touched.city && Boolean(formik.errors.city)}
      helperText={formik.touched.city && formik.errors.city}
      fullWidth variant="outlined" label="City" sx={fieldStyling}
    />

    <div className="row g-2">
      <div className="col-6">
        <TextField 
          name = "state" 
          value = {formik.values.state} 
          onChange = {formik.handleChange}
          onBlur = {formik.handleBlur}
          error={formik.touched.state && Boolean(formik.errors.state)}
          helperText={formik.touched.state && formik.errors.state}
          fullWidth variant="outlined" label="State" sx={fieldStyling}
        />
      </div>

      <div className="col-6">
      <TextField 
        name = "zip" 
        value = {formik.values.zip} 
        onChange = {formik.handleChange}
        onBlur = {formik.handleBlur}
        error={formik.touched.zip && Boolean(formik.errors.zip)}
        helperText={formik.touched.zip && formik.errors.zip}
        fullWidth variant="outlined" label="Zip" sx={fieldStyling}
      />
      </div>
    </div>

    {error && 
    <Typography sx={{color: "#d9534f", marginBottom:"15px", fontSize:"15px", textAlign:"center"}}>
      {error}
    </Typography>
  }

    <TextField 
      name = "email" 
      value = {formik.values.email} 
      onChange = {formik.handleChange}
      onBlur = {formik.handleBlur}
      error={formik.touched.email && Boolean(formik.errors.email)}
      helperText={formik.touched.email && formik.errors.email}
      fullWidth variant="outlined" label="Email" sx={fieldStyling}
      />

    <TextField 
      name = "password" 
      value = {formik.values.password} 
      onChange = {formik.handleChange}
      onBlur = {formik.handleBlur}
      error={formik.touched.password && Boolean(formik.errors.password)}
      helperText={formik.touched.password && formik.errors.password}
      fullWidth variant="outlined" label="Password" type="password" sx={fieldStyling}/>  

    <TextField 
      name = "confirmPassword" 
      value = {formik.values.confirmPassword} 
      onChange = {formik.handleChange}
      onBlur = {formik.handleBlur}
      error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
      helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
      fullWidth variant="outlined" label="Confirm Password" type="password" sx={fieldStyling}/>  

    <div className="d-grid">
      <Button 
        type="submit" 
        size="large"
        variant="contained" 
        sx={{marginTop: "5px", textTransform: "none", backgroundColor:"#d9534f", color: "white", '&:hover': {backgroundColor: "#d44a46"}}}
      >
        Add User
      </Button>

    </div>
  </form>
  )
}

export default AddUserForm