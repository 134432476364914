import useStyleContext from "../../../hooks/contextHooks/useStyleContext"
import { Box, Modal, Typography } from "@mui/material"
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import ModalPrimaryButton from "../../../components/ModalPrimaryButton";
import ModalCloseButton from "../../../components/ModalCloseButton";
import { useNavigate } from "react-router-dom";
import useAuthContext from "../../../hooks/contextHooks/useAuthContext";
import useModalContext from "../../../hooks/contextHooks/useModalContext";
import CustomModal from "../../../components/CustomModal";



const ConfirmLogout = (props) => {

  const {authDispatch, authState, checkAuth} = useAuthContext()
  const {modalStyle, primaryColor} = useStyleContext()
  const {confirmLogoutModal, setConfirmLogoutModal} = useModalContext()
  const navigate = useNavigate()

  const handleLogout = () => {
    localStorage.removeItem("user")
    authDispatch({type:"LOGOUT"})
    navigate("/login")
  }
  
  const closeModal = () => {
    setConfirmLogoutModal(false)
  }

  return (
    <CustomModal
      open = {confirmLogoutModal}
      onClose = {closeModal}
    >
    <Box sx={modalStyle}>

        <>
          <Typography sx={{fontSize: "25px", color: primaryColor}}>
            Are you sure you want to logout?
          </Typography>

          <hr />

          <ModalPrimaryButton 
            buttonText = "Logout"
            onClick = {handleLogout}
          />

          <ModalCloseButton 
            onClick = {closeModal}
          />
        </>

    </Box>

  </CustomModal>
  )
 
}

export default ConfirmLogout