import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography, Button, Tooltip, Box, Grow } from '@mui/material';
import useStyleContext from "../../../../hooks/contextHooks/useStyleContext"
import useModalContext from '../../../../hooks/contextHooks/useModalContext';
import { useState } from 'react';
import CancelPendingModal from "../modals/CancelPendingModal"
import UpdateRequestModal from '../modals/UpdateRequestModal';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Loading from '../../../../components/Loading';

const PendingEvents = (props) => {

  const {primaryColor, headingStyle, rowStyle} = useStyleContext()
  const {setCancelPendingModal, setUpdateRequestModal} = useModalContext()

  const [cancelRequest, setCancelRequest] = useState()
  const [updateRequest, setUpdateRequest] = useState()

  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit"
  }

  const convertDate = (date) => {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + 1)
    const convertedDate = newDate.toLocaleDateString("en-US", options)
    return convertedDate
  }

  const openCancelPendingModal = (request) => {
    setCancelRequest(request)
    setCancelPendingModal(true)
  }

  const openUpdatePendingModal = (request) => {
    setUpdateRequest(request)
    setUpdateRequestModal(true)
  }

  const toolTipText1 = 'Pending Requests are requests that have yet to be reviewed by the booking team. Once approved or denied, you will get an email with more information.'
  const toolTipText2 = 'To submit a new booking request, please visit the "Book Event" tab of this portal.'

  return (
    <>
      <CancelPendingModal 
        request = {cancelRequest}
      />

      <UpdateRequestModal 
        request = {updateRequest}
      />

      <Typography sx={{fontSize: "20px",  marginBottom: "10px", textAlign:"left"}}>
        Pending Requests

        <Tooltip title={toolTipText1} enterTouchDelay={0}>
          <HelpOutlineIcon sx={{color:"gray", margin:"0 0 1px 2px", fontSize:"19px"}}/>
        </Tooltip>

      </Typography>
      <hr />

      {!props.requests ? <Loading loadingText = "Loading your pending events..."/> : ( 
        <>
          {props.requests?.filter(request => request.payStatus == "Pending Review").length == 0 &&
            <div style={{textAlign:"center", margin:"10px"}}>
              <Typography fontSize="13px">
                  <span style={{color:primaryColor, fontSize:"25px", fontWeight:"bold"}}>
                    No pending requests!
                  </span>
                  <br />
                  <Tooltip title={toolTipText2} enterTouchDelay={0}>
                    <HelpOutlineIcon sx={{color:"gray", margin:"0 0 20px 0", fontSize:"17px"}}/>
                  </Tooltip>
              </Typography>
              <Typography fontSize="13px">
                For questions, please email: <span style={{color:primaryColor, fontWeight:"bold"}}>booking@znfashions.com.</span>
              </Typography>
            </div>
          }

          <TableContainer component={Paper} sx={{maxHeight: "500px"}}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow sx={{backgroundColor: primaryColor}}>
                  <TableCell sx={headingStyle}>Event Name</TableCell>
                  <TableCell sx={headingStyle}>Event Date</TableCell>
                  <TableCell sx={headingStyle} align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.requests?.map(request => {
                  if (request.payStatus == "Pending Review") {
                    return (
                      <TableRow
                      key={request._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" sx={rowStyle}>
                        {request.eventName.replace("ZN Fashions ", "")}
                      </TableCell>
                      <TableCell sx={rowStyle}>{convertDate(request.sortDate)}</TableCell>
                      <TableCell align="center" sx={rowStyle}>
                        <Button variant='text' size='small' sx={{color:"green", textTransform:"none", fontSize:"12px"}} onClick={() => openUpdatePendingModal(request)}>
                          Update
                        </Button>
                        <Button variant='text' size='small' sx={{color:"red", textTransform:"none", fontSize:"12px"}} onClick={() => openCancelPendingModal(request)}>
                          Cancel
                        </Button>
                      </TableCell>
                    </TableRow>
                    )
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

   
          

          

    
    </>
  )
}

export default PendingEvents