import {Typography, TextField, Button, Checkbox} from "@mui/material"
import useRegister from '../../hooks/otherHooks/useRegister';
import { useNavigate } from 'react-router-dom';
import {useFormik} from "formik"
import * as yup from "yup"
import useAuthContext from "../../hooks/contextHooks/useAuthContext";
import useModalContext from "../../hooks/contextHooks/useModalContext";
import TermsModal from "./TermsModal";
import Address from "./Address";
import useStyleContext from "../../hooks/contextHooks/useStyleContext";
import { useState } from "react";
import Loading from "../../components/Loading";

const RegisterForm = (props) => {

  const {authState} = useAuthContext()
  const {setTermsModal} = useModalContext()
  const {primaryColor} = useStyleContext()
  const {loading, setLoading} = useModalContext()

  const fieldStyling = {
    margin: "0 0 10px 0",
    width:"100%",
    "& .MuiFormLabel-asterisk": {
      display: "none"
    }
  }

  const navigate = useNavigate()
  const {register, error} = useRegister()


  const openTermsModal = () => {
    setTermsModal(true)
  }


  const onSubmit = async (values) => {

    const fullAddress = document.getElementById("google-map-demo").value
    const addressArray = fullAddress.split(", ")

    const address = addressArray[0]
    const city = addressArray[1]
    const state = addressArray[2]
    const country = addressArray[3]

    const userData = {
      ...values,
      ["address"]: addressArray[0],
      city: addressArray[1],
      state: addressArray[2],
      country: addressArray[3],
    }



    setLoading(true)
    await register(userData)
    setLoading(false)
    
  }


  const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{6,}$/

  const validationSchema = yup.object({
    fName: yup
      .string()
      .min(3,"Please enter a valid first name")
      .required("This field is required"),
    lName: yup
      .string()
      .min(3,"Please enter a valid first name")
      .required("This field is required"),
    companyName: yup
      .string()
      .min(3, "Please enter a valid company name")
      .required("This field is required"),
    phone: yup
      .string()
      .min(14, "Please enter a valid 10-digit phone number")
      .max(14, "Please enter a valid 10-digit phone number")
      .required("This field is required"),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("This field is required"),
    password: yup
      .string()
      .matches(PASSWORD_REGEX, "Please enter a stronger password (must include one of each: uppercase, lowercase, number, 6 characters)")
      .required("This field is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    agreeTerms: yup
      .bool()
      .oneOf([true], "Need to accept")
      .required("This field is required")
  })


  const formik = useFormik(
    {
      initialValues: {
        fName: "",
        lName: "",
        companyName: "",
        phone: "",
        email: "",
        password: "",
        confirmPassword: "",
        agreeTerms: false
      },
    validationSchema: validationSchema,
    validateOnBlur: false,
    onSubmit
    }
  )

  const phoneFormat = (input) => {
    if (!input) return input
    const phoneNumber = input.replace(/[^\d]/g, "")
    const phoneNumberLength = phoneNumber.length

    if (phoneNumberLength < 4){
      return phoneNumber
    } else if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3)}`
    } else {
      return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6,10)}`
    }
  }

  return (

    <>
      <TermsModal />
      {loading ? (
        <Loading 
          loadingText = "Setting up your account, do not click away from this page!"
        />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Typography sx={{fontSize: "30px",  textAlign:"left", color:"#d9534f"}}>
            Register
          </Typography>

          <hr style={{margin: "15px 0 15px 0"}} />
                
          <Typography sx={{textAlign: "left", margin: "10px 0 20px 3px"}}>
            User Info
          </Typography>

          <div className="row g-2">
            <div className="col-6">
              <TextField 
                
                name = "fName" 
                value = {formik.values.fName} 
                onChange = {formik.handleChange}
                onBlur = {formik.handleBlur}
                error={formik.touched.fName && Boolean(formik.errors.fName)}
                helperText={formik.touched.fName && formik.errors.fName}
                fullWidth variant="outlined" label="First Name" sx={fieldStyling}
              />
            </div>
            <div className="col-6">
              <TextField 
                
                name = "lName" 
                value = {formik.values.lName} 
                onChange = {formik.handleChange}
                onBlur = {formik.handleBlur}
                error={formik.touched.lName && Boolean(formik.errors.lName)}
                helperText={formik.touched.lName && formik.errors.lName}
                fullWidth variant="outlined" label="Last Name" sx={fieldStyling}
              />
            </div>
          </div>

          <TextField 
            
            name = "companyName" 
            value = {formik.values.companyName} 
            onChange = {formik.handleChange}
            onBlur = {formik.handleBlur}
            error={formik.touched.companyName && Boolean(formik.errors.companyName)}
            helperText={formik.touched.companyName && formik.errors.companyName}
            fullWidth variant="outlined" label="Company Name" sx={fieldStyling}
          />

          <Typography sx={{textAlign: "left", margin: "10px 0 20px 3px"}}>
            Contact Info
          </Typography>

          <TextField 
            
            name = "phone" 
            inputProps={{ maxLength: 14 }}
            value = {phoneFormat(formik.values.phone)} 
            onChange = {formik.handleChange}
            onBlur = {formik.handleBlur}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            fullWidth variant="outlined" label="Cell Phone" sx={fieldStyling}
          />

          <Address/>

          <Typography sx={{textAlign: "left", margin: "20px 0 20px 3px"}}>
            Account Info
          </Typography>

          {error && 
          <Typography sx={{color: "#d9534f", marginBottom:"15px", fontSize:"15px", textAlign:"center"}}>
            {error}
          </Typography>
          }

          <TextField 
            
            name = "email" 
            value = {formik.values.email} 
            onChange = {formik.handleChange}
            onBlur = {formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            fullWidth variant="outlined" label="Email" sx={fieldStyling}
            />

          <TextField 
            
            name = "password" 
            value = {formik.values.password} 
            onChange = {formik.handleChange}
            onBlur = {formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            fullWidth variant="outlined" label="Password" type="password" sx={fieldStyling}/>  

          <TextField 
            
            name = "confirmPassword" 
            value = {formik.values.confirmPassword} 
            onChange = {formik.handleChange}
            onBlur = {formik.handleBlur}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            fullWidth variant="outlined" label="Confirm Password" type="password" sx={fieldStyling}/> 

          <div className="row mt-2 mb-3 g-0">
            <div className="col-2">
            <Checkbox 
              required
              sx={{color:"lightgray"}}
              name = "agreeTerms"    
              value = {formik.values.agreeTerms}
              onChange = {formik.handleChange}
              // error={formik.touched.agreeTerms && Boolean(formik.errors.agreeTerms)}
              // helpertext={formik.touched.agreeTerms && formik.errors.agreeTerms}
              label="Terms and Conditions"
            />
            </div>
            <div className="col-10">
              <Typography fontSize="14px" color="gray">
                I agree to the 
                <a onClick={openTermsModal} style={{color: primaryColor, textDecoration:"underline", cursor:"pointer"}}>
                  &nbsp;ZN Fashions Vendor Terms and Conditions
                </a> 
              </Typography>
            </div>
          </div>


          <div className="d-grid">
            <Button 
              type="submit" 
              size="large"
              variant="contained" 
              sx={{textTransform: "none", backgroundColor: primaryColor, color: "white", '&:hover': {backgroundColor: "#d44a46"}}}
            >
              Register
            </Button>

            <Button
              variant="outlined" 
              size="large"
              sx={{textTransform: "none", marginTop: "10px", color: "#d44a46", borderColor: "#d44a46", '&:hover': {backgroundColor: "#d44a46", borderColor:"#d44a46", color:"white"}}}
              onClick={() => navigate("/login")}
            >
              Back to Login
            </Button>

          </div>
        </form>
      )}

    </>
  )
}

export default RegisterForm