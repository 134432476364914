import { useState, useEffect } from "react"
import { Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import useBookingContext from "../../../hooks/contextHooks/useBookingContext"
import useModalContext from "../../../hooks/contextHooks/useModalContext";
import ReviewBookingModal from "./modals/ReviewBookingModal";
import ApproveBookingModal from "./modals/ApproveBookingModal";
import DenyBookingModal from "./modals/DenyBookingModal";
import AddBookingModal from "./modals/AddBookingModal";
import { Modal, Box } from "@mui/material"
import useStyleContext from "../../../hooks/contextHooks/useStyleContext";
import ModalCloseButton from "../../../components/ModalCloseButton";
import Loading from "../../../components/Loading";


const NewRequests = () => {

  const {getBookingRequests, bookingRequests} = useBookingContext()
  const {
    setReviewBookingModal, 
    setApproveBookingModal,
    setDenyBookingModal,
    setAddBookingModal,
    setViewImageModal,
    viewImageModal
  } = useModalContext()
  const {modalStyle, primaryColor, headingStyle} = useStyleContext()

  const [booking, setBooking] = useState()
  const [image, setImage] = useState()

  useEffect(() => {
    getBookingRequests("Pending Review")
  }, [])

  const openReviewBookingModal = (request) => {
    setBooking(request)
    setReviewBookingModal(true)
  }

  const openApproveBookingModal = (request) => {
    setBooking(request)
    setApproveBookingModal(true)
  }

  const openDenyBookingModal = (request) => {
    setBooking(request)
    setDenyBookingModal(true)
  }

  const openAddBookingModal = () => {
    setAddBookingModal(true)
  }

  const openViewImageModal = (image) => {
    setImage(image)
    setViewImageModal(true)
  }

  const closeModal = () => {
    setBooking(null)
    setViewImageModal(false)
  }

 
  return (
    <>
      <ReviewBookingModal 
        booking = {booking}
      />

      <ApproveBookingModal
        booking = {booking}
      />

      <DenyBookingModal
        booking = {booking}
      />

      <AddBookingModal/>

      <Modal
        open={viewImageModal}
        onClose={closeModal}
        >
        <Box sx={modalStyle}>
          <Box textAlign="center" marginBottom="10px">
            <Typography sx={{fontSize: "25px", color: primaryColor}}>
              Payment Confirmation
            </Typography>
            <hr />
            <img src={image} height="400px" style={{marginTop:"10px"}}/>
          </Box>    
          <ModalCloseButton
            onClick = {closeModal}
          />
        </Box>
      </Modal>
      
      <div className="container" >
      
      <Typography sx={{fontSize: "22px", marginBottom: "10px", textAlign:"left"}}>
        New Booking Requests
      </Typography>

      {!bookingRequests ? <Loading loadingText = "Loading new booking requests..." /> : (
        <TableContainer component={Paper} sx={{maxHeight: "500px"}}>
          <Table size="small">
            <TableHead>
              <TableRow sx={{backgroundColor: "#df4759"}}>
                <TableCell sx={headingStyle}>Name</TableCell>
                <TableCell sx={headingStyle}>Company</TableCell>
                <TableCell sx={headingStyle}>Event</TableCell>
                <TableCell sx={headingStyle}>Pay Date</TableCell>
                <TableCell sx={headingStyle}>Pay Method</TableCell>
                <TableCell sx={headingStyle} align="center">Pay Conf</TableCell>
                <TableCell sx={headingStyle}>Status</TableCell>
                <TableCell sx={headingStyle} align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookingRequests?.map(request => {
                return (
                  <TableRow
                    key={request._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {request.userFirstName + " " + request.userLastName}
                    </TableCell>
                    <TableCell>{request.userCompanyName}</TableCell>
                    <TableCell>{request.eventName}</TableCell>
                    <TableCell>{request.payDate}</TableCell>
                    <TableCell>{request.payMethod}</TableCell>
                    <TableCell align="center">
                      {request.payConfirmation.length > 1000 ? (
                        <Button 
                          variant="text"
                          onClick={() => openViewImageModal(request.payConfirmation)}
                          sx={{textTransform:"none", color:primaryColor}}
                        >
                          Click to View
                        </Button>
                      ) : (
                        request.payConfirmation
                      )}
                    </TableCell>
                    <TableCell>{request.payStatus}</TableCell>
                    <TableCell align="center">

                      <Button 
                        variant='text' 
                        size='small' 
                        sx={{color:"gray", textTransform: "none"}} 
                        onClick={() => openReviewBookingModal(request)}
                      >
                        Review
                      </Button>

                      <Button 
                        variant='text' 
                        size='small' 
                        sx={{color:"gray", textTransform: "none"}} 
                        onClick={() => openApproveBookingModal(request)}
                      >
                        Approve
                      </Button>


                      <Button 
                        variant='text' 
                        size='small' 
                        sx={{color:"red", textTransform: "none"}} 
                        onClick={() => openDenyBookingModal(request)}
                      >
                        Deny
                      </Button>

                    </TableCell>
                  </TableRow>
                )
              })}
              
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* <div className="row justify-content-center mt-3">
        <Button 
          onClick = {()=> openAddBookingModal()}
          variant="contained" 
          size="large"
          sx={{textTransform:"none", width: "98%", backgroundColor:"#df4759", '&:hover': {backgroundColor: "#d44a46"}}}
        >
          Add Booking
        </Button>
      </div> */}
    </div>


    </>

  )
}

export default NewRequests