import "./PinkCard.css"

const PinkCard = (props) => {

  return (
    <div className="pink-card" >
      {props.children}
    </div>
  )
}

export default PinkCard