import { Box, Modal, Typography } from "@mui/material"
import useModalContext from "../../../../hooks/contextHooks/useModalContext"
import useStyleContext from "../../../../hooks/contextHooks/useStyleContext"
import ModalCloseButton from "../../../../components/ModalCloseButton"
import ModalPrimaryButton from "../../../../components/ModalPrimaryButton"
import useCancelRequest from "../../../../hooks/otherHooks/booking-hooks/useCancelReqest"
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import Loading from "../../../../components/Loading"
import CustomModal from "../../../../components/CustomModal"

const CancelConfirmedModal = (props) => {

  const {modalStyle, primaryColor} = useStyleContext()
  const {cancelConfirmedModal, setCancelConfirmedModal, loading, setLoading} = useModalContext()

  const {cancelRequest, error, setError, success, setSuccess} = useCancelRequest()

  const closeModal = () => {
    setCancelConfirmedModal(false)
    setError(null)
    setSuccess(null)
  }

  const handleConfirm = async () => {
    setLoading(true)
    await cancelRequest(props.request, "Approved")
    setLoading(false)
  }

  return (
    <CustomModal
      open = {cancelConfirmedModal}
      onClose = {closeModal}
    >
      <Box sx={modalStyle}>

        {loading ? (
          <Loading 
            loadingText = "Approved Request being cancelled, do not click away from this page!"
          />
        ) : (
        success ? 
          <Box textAlign="center">
            <h4>Successfully Cancelled Your Confirmed Booking Request!</h4>
            <CheckBoxRoundedIcon sx={{color: "green", fontSize: "200px", marginBottom: "10px"}} />
            <ModalCloseButton
              onClick = {closeModal}
            />
          </Box>
        : 
          <>
            <Typography sx={{fontSize: "25px", color: primaryColor}}>
              Are you sure you want to cancel your booking?
            </Typography>

            <hr />

            <Typography sx={{fontSize: "15px",  textAlign:"left", marginBottom:"15px"}}>
              Please review the below <strong style={{color:primaryColor}}>cancellation penalties</strong> before confirming. For questions, 
              please <strong style={{color:primaryColor}}> email booking@znfashions.com</strong> before clicking cancel.
            </Typography>

            <hr style={{margin: "15px 0 15px 0"}} />

            <Typography sx={{fontSize: "18px", color: primaryColor, textAlign:"center"}}>
              ZN Fashions Cancellation Policy
            </Typography>

            <hr style={{margin: "15px 0 15px 0"}} />


            <ul style={{marginTop:"10px"}}>
              <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>&lt; 7 days before event: </strong> no refund, no transfer of funds to other events</li>
              <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>&gt; 7 days before event: </strong> no refund, can transfer funds to other events</li>
              <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>&gt; 30 day before event:</strong> refund available or transfer funds to other events</li>
            </ul>

            <Typography sx={{fontSize: "13px", fontStyle:"italic", color: primaryColor, textAlign:"left", marginBottom:"15px"}}>
              NOTE: We will coordinate refunds/transferring funds to other events through the email sent to you once you click confirm
              button below.
            </Typography>

            {error && 
              <Typography sx={{color: "red", marginBottom:"15px", fontSize:"15px", textAlign:"center"}}>
                {error}
              </Typography>
            }

            <ModalPrimaryButton 
              buttonText = "Confirm"
              onClick = {handleConfirm}
            />

            <ModalCloseButton 
              onClick = {closeModal}
            />
          </>


        )}

      </Box>

    </CustomModal>
  )
}

export default CancelConfirmedModal