import { Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useEffect, useState } from "react"
import useUsersContext from '../../../hooks/contextHooks/useUsersContext';
import useStyleContext from '../../../hooks/contextHooks/useStyleContext';
import BookingsGrid from './BookingsGrid';


const ManageBookings = (props) => {

  const {headingStyle} = useStyleContext()
  const {fetchUsers, users} = useUsersContext()

  const [deactivateUserInfo, setDeactivateUserInfo] = useState()
  const [detailsUser, setDetailsUser] = useState()

  useEffect(() => {
    fetchUsers()
  }, [])


  return (
  
    <>
      <Typography fontSize="30px" mb={2}>
        Manage Bookings
      </Typography>
      <hr />
  
      <div className="container" style={{marginTop:"40px"}} >
        <TableContainer component={Paper}  sx={{maxHeight: "800px"}}>
          <BookingsGrid />
        </TableContainer>
      </div>
    </>
  
  )

}

export default ManageBookings