import * as React from 'react';  
import NewRequests from './NewRequests';
import UpcomingEvents from './UpcomingEvents';
import { Typography } from '@mui/material';

const AdminOverview = () => {
  return(
    <>
      <Typography fontSize="30px" mb={2}>
        Overview
      </Typography>
      <hr />
      <div className="container">
        <div className="row">
          <UpcomingEvents/>
        </div>
        <div className="row mt-4">
          <NewRequests/>
        </div>
      </div>


    </>
  )
}

export default AdminOverview