import { Button } from "@mui/material";

const ModalCloseButton = (props) => {

  return (
    <Button 
      variant="outlined"
      size="large"
      onClick={props.onClick} 
      sx={{marginTop: "10px", width: "100%", borderColor: "#d44a46", textTransform: "none", color: "#d44a46", '&:hover': {color: "white", backgroundColor: "#d44a46", borderColor:"#d44a46"}}}
    >
    Close
  </Button>  
  )
}

export default ModalCloseButton