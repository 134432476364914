import NavBar from "../global/navbar/NavBar"
import Banner from "./landing-banner/Banner"
import Footer from "../global/footer/Footer"
import Collage from "../../components/collage/Collage"
import SignUp from "./landing-event-alerts/EventAlerts"
import UpcomingEvents from "./landing-events/UpcomingEvents"
import AboutUs from "./landing-about/AboutUs"
import Contact from "./landing-contact/Contact"

import image1 from "../../images/newpic1.jpg"
import image2 from "../../images/newpic2.jpg"
import image3 from "../../images/newpic3.jpg"
import image7 from "../../images/newpic7.jpg"
import image8 from "../../images/newpic8.jpg"
import image9 from "../../images/newpic9.jpg"

const Landing = () => {

  return (
    <>
      <NavBar/>
      <Banner/>
      <Collage
        filepath1 = {image1}
        filepath2 = {image2}
        filepath3 = {image3}
      />
      <SignUp/>
      <UpcomingEvents/>
      <AboutUs/>
      <Collage
        filepath1 = {image7}
        filepath2 = {image8}
        filepath3 = {image9}
      />
      <Contact/>
      <Footer/>
    </>
  )
}

export default Landing