import { createContext } from "react";

export const StyleContext = createContext()

const StyleContextProvider = (props) => {

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    overflowY: "auto",
    maxHeight: "90%"
  }

  const primaryColor = "#d9534f"

  const headingStyle = {
    fontSize: "14px",
    color: "white",
    backgroundColor: primaryColor
  }

  const rowStyle = {
    fontSize: "13px"
  }
  

  return (
    <StyleContext.Provider
      value = {{
        modalStyle,
        primaryColor,
        headingStyle,
        rowStyle
      }}
    >
      {props.children}
    </StyleContext.Provider>
  )
}

export default StyleContextProvider