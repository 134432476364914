import * as React from 'react';
import useStyleContext from '../../../hooks/contextHooks/useStyleContext';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, Tooltip, Slide, Box } from '@mui/material';
import Loading from '../../../components/Loading';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const SoldOutEvents = (props) => {

  const {primaryColor, headingStyle, rowStyle} = useStyleContext()

  const navigate = useNavigate()

  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit"
  }

  const convertDate = (date) => {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + 1)
    const convertedDate = newDate.toLocaleDateString("en-US", options)
    return convertedDate
  }

  const [detailsEvent, setDetailsEvent] = useState()
  const handleDetailsEvent = (event) => {
    setDetailsEvent(event)
    navigate("/admin/eventdetails/?_id=" + event._id)
    // setDetailsEventModal(true)
  }

  return (
    <>
      {!props.eventDetails ? <Loading loadingText = "Loading sold out events..."/> : ( 
        <Box>
          <TableContainer component={Paper} sx={{maxHeight: "500px"}}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow sx={{backgroundColor: primaryColor}}>
                  <TableCell sx={headingStyle}>Name</TableCell>
                  <TableCell sx={headingStyle}>Date</TableCell>
                  <TableCell sx={headingStyle}>Price</TableCell>
                  <TableCell sx={headingStyle}>Booths Booked</TableCell>
                  <TableCell sx={headingStyle}>Booths Total</TableCell>
                  <TableCell sx={headingStyle} align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                
                {props.eventDetails?.map(event => {
                  if (event.boothsTotal - event.boothsBooked <= 0) {
                    return(
                      <TableRow key={event._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row" sx={rowStyle}>{event.name.replace("ZN Fashions ", "")}</TableCell>
                        <TableCell sx={rowStyle}>{convertDate(event.sortDate)}</TableCell>
                        <TableCell sx={rowStyle}>{"$" + event.boothPrice}</TableCell>
                        <TableCell sx={rowStyle}>{event.boothsBooked}</TableCell>
                        <TableCell sx={rowStyle}>{event.boothsTotal}</TableCell>
                        <TableCell align="center">
                          <Button 
                            variant='text' 
                            size='small' 
                            sx={{color:"gray", textTransform: "none"}} 
                            onClick={() => handleDetailsEvent(event)}
                          >
                            Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  }
                })}
              
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      
    </>
  )
}

export default SoldOutEvents