import { useNavigate } from "react-router-dom"
import "./Contact.css"
import ModalPrimaryButton from "../../../components/ModalPrimaryButton"

const Contact = () => {
  
  const navigate = useNavigate()

  return (
    <div className="container-fluid contact-container" id="contact-us">

    <div className="container">

        <div className="row">
            <div className="col">
                <h1><strong>Contact Us</strong></h1>
            </div>
        </div>

        <div className="row contact-cards">
            <div className="col-lg-6">
                <div className="card mb-4 rounded-3 shadow-sm">
                    <div className="card-header py-3 text-center">
                        <h4 className="my-0 fw-bold">Online Booth Booking</h4>
                    </div>
                    <div className="card-body">
                      <ModalPrimaryButton
                        onClick={() => navigate("/login")}
                        buttonText = "Book Now!"
                        fontSize="20px"
                      />
                    </div>
                    
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card mb-4 rounded-3 shadow-sm">
                  <div className="card-header py-3 text-center" >
                    <h4 className="my-0 fw-bold">Shopping (Chicago Area Only)</h4>
                  </div>
                  <div className="card-body text-center" >
                    <h1 className="card-title pricing-card-title">630-600-1328</h1>
                  </div>
                </div>
              </div>

        </div>

      </div>

    </div>
  )
}

export default Contact