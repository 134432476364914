import { Typography, Button } from "@mui/material"
import useStyleContext from "../../hooks/contextHooks/useStyleContext"

const ProfileField = (props) => {

  const {primaryColor} = useStyleContext()

  return (
    <>
    
    
    <div className="col-6">
      {!props.state && 
        <Typography>
          {props.userProperty}
        </Typography>
      }


      {props.state &&
          <form onSubmit={props.onSubmit}>
            <input required id = {props.id} placeholder={props.userProperty} type={props.email ? "email" : "text"} />
            <br />
            <Button
              type="submit"
              size="small"
              sx={{margin: "10px 5px 0 0",textTransform: "none", backgroundColor:primaryColor, color: "white", '&:hover': {backgroundColor: "#d44a46"}}}
            >
              Save
            </Button>

            <Button
              size="small"
              onClick = {props.setEditMode}
              variant="outlined"
              sx={{marginTop: "10px", textTransform:"none", color:primaryColor, borderColor:primaryColor, '&:hover': {color: "white", backgroundColor: primaryColor, borderColor:primaryColor}}}
            >
              Cancel
            </Button>
          </form>
        }
    </div>


    <div className="col-3">
    {!props.state &&
        <>
          <Button
              size="small"
              onClick = {props.setEditMode}
              variant="text"
              sx={{marginLeft:"20px", textTransform:"none", color:primaryColor, padding:"0"}}
            >
              Edit
            </Button>
        </>
       
      }


    </div>



       

    
    </>
  
      
   
  )
}

export default ProfileField