import { Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useEffect, useState } from "react"
import useUsersContext from '../../../hooks/contextHooks/useUsersContext';
import DeactivateUserModal from './DeactivateUserModal';
import useModalContext from  "../../../hooks/contextHooks/useModalContext"
import AddUserModal from './AddUserModal';
import DetailsUserModal from './DetailsUserModal';
import useStyleContext from '../../../hooks/contextHooks/useStyleContext';
import UserGrid from './UserGrid';


const ManageUsers = () => {


  const {headingStyle} = useStyleContext()
  const {fetchUsers, users} = useUsersContext()
  const {deactivateUserModal, setDeactivateUserModal, addUserModal, setAddUserModal, detailsUserModal, setDetailsUserModal} = useModalContext()

  const [deactivateUserInfo, setDeactivateUserInfo] = useState()
  const [detailsUser, setDetailsUser] = useState()

  useEffect(() => {
    fetchUsers()
  }, [])

  const handleAddUser = () => {
    setAddUserModal(true)
  }

  const handleDeactivateUser = (user) => {
    setDeactivateUserInfo(user)
    setDeactivateUserModal(true)
  }

  const handleDetailsUser = (user) => {
    setDetailsUser(user)
    setDetailsUserModal(true)
  }

  return (
    <>

    <DeactivateUserModal 
      open = {deactivateUserModal}
      onClose = {() => setDeactivateUserModal(false)}
      user = {deactivateUserInfo}
    />

    <AddUserModal 
      open = {addUserModal}
      onClose = {() => setAddUserModal(false)}
    />

    <DetailsUserModal
      open = {detailsUserModal}
      onClose = {() => setDetailsUserModal(false)}
      user = {detailsUser}
    />

    <Typography fontSize="30px" mb={2}>
      Manage Users
    </Typography>
    <hr />

    <div className="container" style={{marginTop:"40px"}} >
      <TableContainer component={Paper}  sx={{maxHeight: "800px"}}>
        <UserGrid />
      </TableContainer>
    </div>
    </>

  )
}

export default ManageUsers