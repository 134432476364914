import "./Collage.css"

const Collage = (props) => {

  return (

    <div className="container-fluid collage-container" id="signup">

      <div className="row collage">

          <div className="col">
              <img src={props.filepath1} alt=""/>
          </div>
              
          <div className="col">
              <img src={props.filepath2} alt=""/>
          </div>

          <div className="col">
              <img src={props.filepath3} alt=""/>
          </div>

      </div>

    </div>

  )
  
}

export default Collage