import useModalContext from "../../../../hooks/contextHooks/useModalContext"
import useStyleContext from "../../../../hooks/contextHooks/useStyleContext"
import useCancelRequest from "../../../../hooks/otherHooks/booking-hooks/useCancelReqest"
import { Box, Modal, Typography } from "@mui/material"
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

import ModalPrimaryButton from "../../../../components/ModalPrimaryButton"
import ModalCloseButton from "../../../../components/ModalCloseButton"
import Loading from "../../../../components/Loading";
import CustomModal from "../../../../components/CustomModal";

const CancelPendingModal = (props) => {

  const {modalStyle, primaryColor} = useStyleContext()
  const {cancelPendingModal, setCancelPendingModal, loading, setLoading} = useModalContext()

  const {cancelRequest, error, setError, success, setSuccess} = useCancelRequest()
  
  const closeModal = () => {
    setCancelPendingModal(false)
    setError(null)
    setSuccess(null)
  }

  const handleConfirm = async () => {
    setLoading(true)
    await cancelRequest(props.request, "Pending Review")
    setLoading(false)
  }

  return (
    <CustomModal
      open = {cancelPendingModal}
      onClose = {closeModal}
    >
      <Box sx={modalStyle}>

        {loading ? (
          <Loading
            loadingText = "Pending Request being cancelled, do not click away from this page!"
          />
        ) : (
        success ? 
          <Box textAlign="center">
            <h4>Successfully Cancelled Your Pending Booking Request!</h4>
            <CheckBoxRoundedIcon sx={{color: "green", fontSize: "200px", marginBottom: "10px"}} />
            <ModalCloseButton
              onClick = {closeModal}
            />
          </Box>
        : 
          <>
            <Typography sx={{fontSize: "25px", color: primaryColor}}>
              Are you sure you want to cancel your pending request?
            </Typography>

            <hr />

            <Typography sx={{fontSize: "15px",  textAlign:"left", marginBottom:"15px"}}>
              There is no penalty for cancelling a pending request, click below to confirm.
            </Typography>

            {error && 
              <Typography sx={{color: "red", marginBottom:"15px", fontSize:"15px", textAlign:"center"}}>
                {error}
              </Typography>
            }

            <ModalPrimaryButton 
              buttonText = "Confirm"
              onClick = {handleConfirm}
            />

            <ModalCloseButton 
              onClick = {closeModal}
            />
          </>
        )}

      </Box>

    </CustomModal>
  )
}

export default CancelPendingModal