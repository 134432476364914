import {useEffect, useState} from "react"
import PropTypes from 'prop-types';
import { Typography, Button, Slide, Box, Grow, Paper, Tab, Tabs } from '@mui/material';
import useModalContext from '../../../hooks/contextHooks/useModalContext';
import useStyleContext from '../../../hooks/contextHooks/useStyleContext';
import useEventsContext from '../../../hooks/contextHooks/useEventsContext';
import useAuthContext from "../../../hooks/contextHooks/useAuthContext";
import { styled } from '@mui/material/styles';
import AvailableEvents from "./AvailableEvents";
import SoldOutEvents from "./SoldOutEvents";
import HistoricEvents from "./HistoricEvents";
import AddEventModal from "../events/AddEventModal";
import ModalPrimaryButton from "../../../components/ModalPrimaryButton";

const TabPanel = (props) => {
  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding:"30px 0" }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const ManageEvents = () => {

  const {authState} = useAuthContext()
  const {primaryColor} = useStyleContext()
  const [value, setValue] = useState(0);

  const {bookingModal, setAddEventModal} = useModalContext()
  const {fetchEventDetails, eventDetails} = useEventsContext()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const AntTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent'
    },
    '& .MuiTabs-indicatorSpan': {
      width: '100%',
      backgroundColor: primaryColor,
    },
  });

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    minWidth: 0,
    fontSize:"13px",
    width:"20px",
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    '&:hover': {
      color: primaryColor,
      opacity: 1,
    },
    '&.Mui-selected': {
      color: primaryColor,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: primaryColor,
    },
  }));

  useEffect(() => {
    fetchEventDetails()
  }, [])

  const handleAddEvent = () => {
    setAddEventModal(true)
  }

  return (
    <>
    <AddEventModal/>
    <Box sx={{ width: '100%'}}>

      <div className="row">
        <div className="col">
          <Typography fontSize="30px">
            Manage Events
          </Typography>
        </div>
        <div className="col" style={{textAlign:"right"}}>
          <Button 
            onClick={handleAddEvent}
            sx={{marginTop: "5px", textTransform: "none", backgroundColor:"#d9534f", color: "white", '&:hover': {backgroundColor: "#d44a46"}}}
          >
            Add Event
          </Button>
        </div>
      </div>


      <hr />

      
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <AntTabs value={value} variant='fullWidth' onChange={handleChange} >
          <AntTab label="Available Events" {...a11yProps(0)}/>
          <AntTab label="Sold Out Events" {...a11yProps(1)} />
          <AntTab label="Historic Events" {...a11yProps(2)} />
        </AntTabs>
      </Box>
      
      <Box>
        <TabPanel value={value} index={0}>
          <AvailableEvents eventDetails = {eventDetails}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SoldOutEvents eventDetails = {eventDetails}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <HistoricEvents eventDetails = {eventDetails}/>
        </TabPanel>
      </Box>

    </Box>
      
 
    </>
  )
}

export default ManageEvents