import {Typography, Button} from "@mui/material"
import NavBar from "../global/navbar/NavBar"
import RegisterForm from "./RegisterForm"
import { useNavigate } from 'react-router-dom';
import useModalContext from "../../hooks/contextHooks/useModalContext";
import Loading from "../../components/Loading";
import Footer from "../global/footer/Footer";

const Register = () => {

  const navigate = useNavigate()

  window.scrollTo(0, 0)

  return (
    <> 
      <NavBar
        admin = {true}
      />
      

      <div className="container" style={{
          width: "320px",
          margin: "150px auto 60px auto",
      }}>


          <>
            <RegisterForm/>
          </>

      </div>
      <Footer/>
    </>
  )
}

export default Register