import { Typography, Box, TextField, MenuItem, FormControl, InputLabel, Select,  } from "@mui/material"
import CustomModal from "../../../../components/CustomModal"
import useModalContext from "../../../../hooks/contextHooks/useModalContext"
import useStyleContext from "../../../../hooks/contextHooks/useStyleContext"
import Address from "../../../register/Address"
import { useState } from "react";
import ModalPrimaryButton from "../../../../components/ModalPrimaryButton"
import ModalCloseButton from "../../../../components/ModalCloseButton"
import useUpdateEvent from "../../../../hooks/otherHooks/event-hooks/useUpdateEvent"

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const UpdateEventModal = (props) => {

  const {updateEventModal, setUpdateEventModal} = useModalContext()
  const {modalStyle, primaryColor} = useStyleContext()

  const {updateEvent, success, error, setError, setSuccess} = useUpdateEvent()

  const closeModal = () => {
    setUpdateEventModal(false)
    setError(false)
    setSuccess(false)
  }

  const [startTime, setStartTime] = useState("")
  const [endTime, setEndTime] = useState("")
  const [boothPrice, setBoothPrice] = useState("")

  const handleChangeStartTime = (e) => {
    setStartTime(e.target.value)
  }
  const handleChangeEndTime = (e) => {
    setEndTime(e.target.value)
  }
  
  const handleChangeBoothPrice = (e) => {
    setBoothPrice(e.target.value)
  }

  const handleUpdate = (e) => {
    e.preventDefault()

    const fullAddress = e.target[7].value
    const addressArray = fullAddress.split(", ")

    const address = addressArray[0]
    const city = addressArray[1]
    const state = addressArray[2]
    const country = addressArray[3]

    const updateEventInfo = {
      id: props.event._id,
      name: e.target[0].value,
      date: e.target[2].value,
      link: e.target[5].value,
      address: address,
      city: city,
      state: state,
      country: country,
      startTime: e.target[11].value,
      endTime: e.target[13].value,
      boothsTotal: e.target[15].value,
      boothsBooked: e.target[17].value,
      boothPrice: boothPrice
    }

    updateEvent(updateEventInfo)
  }

  return (
    <CustomModal
      open = {updateEventModal}
      onClose = {closeModal}
    >
      <Box sx={modalStyle}>
        <Typography sx={{fontSize: "25px", color: primaryColor}}>
          Update Event Details
        </Typography>
        {error && 
          <h6 style={{color: "red", textAlign:"center"}}>{error}</h6>
        }
          <Typography mb={1}>
            General Info
          </Typography>
          <hr />
          <form onSubmit = {handleUpdate}>

            <TextField 
              required
              fullWidth
              name="eventName"
              label= "Event Name"
              sx={{"& .MuiFormLabel-asterisk": {
                display: "none"
              }}}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker 
                required
                fullWidth
                name="eventDate"
                label="Event Date"
                sx={{marginTop:"10px", width:"100%", "& .MuiFormLabel-asterisk": {
                  display: "none"
                }}}
              />
            </LocalizationProvider>

            <TextField 
              required
              fullWidth
              name="eventLink"
              label= "Facebook Link"
              sx={{margin:"10px 0", "& .MuiFormLabel-asterisk": {
                display: "none"
              }}}
            />

            <hr />

            <Typography mb={2}>
              Specifics
            </Typography>

            <Address/>

            <div className="row g-2">
              <div className="col-6">
                <FormControl fullWidth>
                  <InputLabel id="startTime">Start Time</InputLabel>
                  <Select 
                    required
                    name = "startTime" 
                    fullWidth variant="outlined" label="Start Time"
                    value={startTime}
                    onChange={handleChangeStartTime}
                  >

                    <MenuItem value = "10">10am</MenuItem>
                    <MenuItem value = "11">11am</MenuItem>
                    <MenuItem value = "12">12pm</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col-6">
                <FormControl fullWidth>
                  <InputLabel id="endTime">End Time</InputLabel>
                  <Select 
                    required
                    name = "endTime" 
                    fullWidth variant="outlined" label="End Time"
                    value={endTime}
                    onChange={handleChangeEndTime}
                  >

                    <MenuItem value = "6">6pm</MenuItem>
                    <MenuItem value = "7">7pm</MenuItem>
                    <MenuItem value = "8">8pm</MenuItem>
                    <MenuItem value = "9">9pm</MenuItem>
                    <MenuItem value = "10">10pm</MenuItem>
                    <MenuItem value = "11">11pm</MenuItem>
                    
                  </Select>
                </FormControl>
              </div>
            </div>


            <hr />
            
            
            <Typography>
              Booth Info
            </Typography>


            <div className="row mt-2 mb-3 g-2">
              <div className="col-4">

                <TextField 
                  required
                  fullWidth
                  type="number"
                  name="boothsTotal"
                  label= "Total"
                  sx={{"& .MuiFormLabel-asterisk": {
                    display: "none"
                  }}}
                />

                </div>

                <div className="col-4">
                  <TextField 
                    required
                    fullWidth
                    type="number"
                    name="boothsBooked"
                    label= "Booked"
                    sx={{"& .MuiFormLabel-asterisk": {
                      display: "none"
                    }}}
                  />

                </div>

                <div className="col-4">

                  <FormControl fullWidth>
                    <InputLabel id="boothPrice">Price</InputLabel>

                    <Select 
                      required
                      name = "boothPrice" 
                      fullWidth variant="outlined" label="Price"
                      value={boothPrice}
                      onChange={handleChangeBoothPrice}
                    >
                      <MenuItem value = {250}>$250</MenuItem>
                      <MenuItem value = {275}>$275</MenuItem>
                      <MenuItem value = {300}>$300</MenuItem>
                      <MenuItem value = {325}>$325</MenuItem>
                      <MenuItem value = {350}>$350</MenuItem>
                      <MenuItem value = {375}>$375</MenuItem>
                      <MenuItem value = {400}>$400</MenuItem>
                    </Select>
                  </FormControl>    
                </div>

            </div>

            <ModalPrimaryButton 
              type = "submit"
              buttonText = "Update"
            />
            <ModalCloseButton
              onClick = {closeModal}
            />
          </form>
        
      </Box>
    </CustomModal>
  )
}


export default UpdateEventModal