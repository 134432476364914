import { useState, useEffect } from "react"
import {useNavigate} from "react-router-dom"
import useStyleContext from "../../hooks/contextHooks/useStyleContext"
import {Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography, AppBar, Grow, Slide} from "@mui/material" 
import {Menu, CalendarMonth, Event, Person, LogoutOutlined, Description} from '@mui/icons-material'
import BookingModal from "../admin/booking-modal/BookingModal"
import logo from "../global/navbar/ZN Font.png"
import MyBookings from "./my-bookings/MyBookings"
import BookEvent from "./book-event/BookEvent"
import ProfilePage from "../profile/ProfilePage"
import useAuthContext from "../../hooks/contextHooks/useAuthContext"
import TermsModal from "../register/TermsModal"
import useModalContext from "../../hooks/contextHooks/useModalContext"
import Terms from "../register/Terms"
import AccountMenu from "../global/navbar/AccountMenu"
import ConfirmLogout from "./modals/ConfirmLogout"

const drawerWidth = 240;

const VendorPortal = (props) => {

  const [tab, setTab] = useState("My Bookings")
  const {checkAuth} = useAuthContext()
  const {primaryColor} = useStyleContext()
  const {setConfirmLogoutModal} = useModalContext()
  const navigate = useNavigate()

  useEffect(() => {
    checkAuth()
  }, [tab])

  // ----- Sidebar Stuff Start -----//
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <img src={logo} width="175px" style={{marginLeft:"30px", marginTop:"20px", marginBottom:"20px", cursor:"pointer"}} onClick={() => navigate("/")}/>
      <Divider />
      <List>

      <Slide direction="right" in={true} mountOnEnter unmountOnExit {...{ timeout: 200 }}>
        <ListItem disablePadding
          onClick={() => {
            setTab("My Bookings") 
            handleDrawerToggle()
          }}
        >
          <ListItemButton>
            <ListItemIcon >
              <CalendarMonth />
            </ListItemIcon>
            <ListItemText primary="My Bookings" />
          </ListItemButton>
        </ListItem>
        </Slide>

        <Slide direction="right" in={true} mountOnEnter unmountOnExit {...{ timeout: 400 }}>
        <ListItem disablePadding
          onClick={() => {
            setTab("Book Event")
            handleDrawerToggle()
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <Event />
            </ListItemIcon>
            <ListItemText primary="Book Event" />
          </ListItemButton>
        </ListItem>
        </Slide>

      </List>

      <Divider />

      <List>
        
        <Slide direction="right" in={true} mountOnEnter unmountOnExit {...{ timeout: 600 }}>
          <ListItem disablePadding
            onClick={() => {
              setTab("Profile")
              handleDrawerToggle()
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItemButton>
          </ListItem>
        </Slide>

        <Slide direction="right" in={true} mountOnEnter unmountOnExit {...{ timeout: 800 }}>
        <ListItem disablePadding
          onClick={() => {      
            setTab("Terms")
            handleDrawerToggle()
          }}
         >
          <ListItemButton>
            <ListItemIcon>
              <Description />
            </ListItemIcon>
            <ListItemText primary="Vendor Terms" />
          </ListItemButton>
        </ListItem>
        </Slide>

        <Slide direction="right" in={true} mountOnEnter unmountOnExit {...{ timeout: 1000 }}>
        <ListItem disablePadding
          onClick={() => setConfirmLogoutModal(true)}
        >
          <ListItemButton>
            <ListItemIcon>
              <LogoutOutlined />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
        </Slide>
      </List>

    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;


  // ----- Sidebar Stuff End ----- //


  return (

    <>

    <BookingModal/>
    <TermsModal/>
    <ConfirmLogout/>

    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` }, backgroundColor:primaryColor
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }}}
          >
            <Menu />
          </IconButton>

          <Typography variant="h6" noWrap component="div">
            Vendor Portal
          </Typography>
          
          <div style={{position:"absolute", right:"20px",}}>
            <AccountMenu/>
          </div>

        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Slide direction="down" in={true} mountOnEnter unmountOnExit {...{ timeout: 600 }}>
          <Typography fontSize="30px" mb={2}>
            {tab == "My Bookings" && "My Bookings"}
            {tab == "Book Event" && "Book Event"}
            {tab == "Profile" && "Profile"}
            {tab == "Terms" && "Vendor Terms and Conditions"}
          </Typography>
        </Slide>

          {tab == "My Bookings" && <MyBookings/>}
          {tab == "Book Event" && <BookEvent/>}
          {tab == "Profile" && <ProfilePage/>}
          {tab == "Terms" && (
            <Slide direction="right" in={true} mountOnEnter unmountOnExit {...{ timeout: 500 }}>
              <Box>
                <hr />
                <Typography fontSize="13px">
                  Please review the below policies. With each event booking request, you are agreeing to the below terms and conditions.
                </Typography>
                <Terms/>
              </Box>
            </Slide>
          )}

      </Box>
    </Box>
      
    </>
  )
}

export default VendorPortal