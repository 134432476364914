import "./Banner.css"
import { Button } from "@mui/material"
import {useNavigate} from "react-router-dom"

const Banner = () => {

  const navigate = useNavigate()

  return (
    <div className="container-fluid banner" id="home">

    <div className="container tagline">
      
      <h1 className="banner-text mb-4"><strong>Welcome to Better Shopping</strong></h1>

      <Button
        variant="contained"
        size="large"
        onClick = {() => window.location.href='#upcoming-events'}
        sx = {{margin: "0 10px 10px 0", textTransform:"none", backgroundColor:"white", color:"black", '&:hover': {backgroundColor: "lightgrey"}}}
      >
        Upcoming Events
      </Button>

      <Button
        variant="outlined"
        size="large"
        onClick = {() => navigate("/login")}
        sx = {{margin: "0 10px 10px 0", width:"166px", textTransform:"none", borderColor:"white", color:"white", '&:hover': {backgroundColor: "white", color: "black", border: "#DC3545"}}}
      >
        Vendor Login
      </Button>

    </div>

    </div>

  )
}

export default Banner