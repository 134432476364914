import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { EventsContextProvider } from './context/EventsContext';
import { AuthContextProvider } from './context/AuthContext';
import ModalContextProvider from './context/ModalContext';
import {MailChimpContextProvider} from './context/MailChimpContext';
import {disableReactDevTools} from "@fvilers/disable-react-devtools"
import { UsersContextProvider } from './context/UsersContext';
import {BookingContextProvider} from "./context/BookingContext"
import StyleContextProvider from './context/StyleContext';


disableReactDevTools()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <MailChimpContextProvider>
        <EventsContextProvider>
          <ModalContextProvider>
            <UsersContextProvider>
              <BookingContextProvider>
                <StyleContextProvider>
                  <App />
                </StyleContextProvider>
              </BookingContextProvider>
            </UsersContextProvider>
          </ModalContextProvider>
        </EventsContextProvider>
      </MailChimpContextProvider>
    </AuthContextProvider>
  </BrowserRouter>
);