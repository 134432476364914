import {useEffect, useState} from "react"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import ConfirmedEvents from "./grids/ConfirmedEvents"
import useStyleContext from '../../../hooks/contextHooks/useStyleContext';
import PendingEvents from "./grids/PendingEvents"
import DeniedEvents from "./grids/DeniedEvents"
import CancelledEvents from "./grids/CancelledEvents"
import useBookingContext from "../../../hooks/contextHooks/useBookingContext"
import useModalContext from "../../../hooks/contextHooks/useModalContext"
import useAuthContext from "../../../hooks/contextHooks/useAuthContext"
import useEventsContext from "../../../hooks/contextHooks/useEventsContext"

import { styled } from '@mui/material/styles';
import { Slide } from "@mui/material";

const TabPanel = (props) => {
  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding:"30px 0" }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const MyBookings = (props) => {

  const {primaryColor} = useStyleContext()
  const [value, setValue] = useState(0);
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const AntTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent'
    },
    '& .MuiTabs-indicatorSpan': {
      width: '100%',
      backgroundColor: primaryColor,
    },
  });
  
  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    minWidth: 0,
    fontSize:"13px",
    width:"20px",
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    '&:hover': {
      color: primaryColor,
      opacity: 1,
    },
    '&.Mui-selected': {
      color: primaryColor,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: primaryColor,
    },
  }));


  const {authState} = useAuthContext()

  const {
    bookEvent, 
    getBookingRequests,
    bookingRequests
  } = useBookingContext()


  const {setBookingModal} = useModalContext()

  useEffect(() => {
    getBookingRequests("undefined", authState.id)
  }, [])

  return (

    <Box sx={{ width: '100%' }}>
      
      <Slide direction="left" in={true} mountOnEnter unmountOnExit {...{ timeout: 600 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <AntTabs value={value} variant='fullWidth' onChange={handleChange} >
            <AntTab label="Confirmed" {...a11yProps(0)}/>
            <AntTab label="Pending" {...a11yProps(1)} />
            <AntTab label="Denied" {...a11yProps(2)} />
            <AntTab label="Cancelled" {...a11yProps(2)} />
          </AntTabs>
        </Box>
      </Slide>

      <Slide direction="up" in={true} mountOnEnter unmountOnExit {...{ timeout: 600 }}>
        <Box>
          <TabPanel value={value} index={0}>
            <ConfirmedEvents requests = {bookingRequests}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PendingEvents requests = {bookingRequests}/>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <DeniedEvents requests = {bookingRequests}/>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <CancelledEvents requests = {bookingRequests}/>
          </TabPanel>
        </Box>
      </Slide>

    </Box>

  );
}

export default MyBookings
