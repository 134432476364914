import { Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import useModalContext from "../../../hooks/contextHooks/useModalContext"
import { useState, useEffect } from "react"
import useStyleContext from '../../../hooks/contextHooks/useStyleContext';
import useBookingContext from '../../../hooks/contextHooks/useBookingContext';

const Pending = (props) => {

  const {primaryColor, headingStyle} = useStyleContext()
  const {bookingRequests} = useBookingContext()

  const [filteredRequests, setFilteredRequests] = useState()

  useEffect(() => {
    setFilteredRequests(bookingRequests?.filter(request => request.payStatus == "Denied" && request.eventID == props?.eventID))
  }, [bookingRequests])

  return (
    <>
      <Typography sx={{fontSize: "22px",  marginBottom: "10px", textAlign:"left"}}>
        Denied Requests
      </Typography>

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow sx={{backgroundColor: primaryColor}}>
              <TableCell sx={headingStyle}>Name</TableCell>
              <TableCell sx={headingStyle}>Pay Date</TableCell>
              <TableCell sx={headingStyle}>Deny Date</TableCell>
              <TableCell sx={headingStyle} align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRequests?.map(request => {
              return (
                <TableRow
                key={request._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {request.userFirstName + " " + request.userLastName}
                </TableCell>
                <TableCell>{request.payDate}</TableCell>
                <TableCell>{request.denyDate}</TableCell>
                <TableCell align="center">
                  <Button 
                    variant='text'
                    size='small' 
                    sx={{color:"green", textTransform:"none", textTransform:"none"}} 
                    onClick={() => props.approve(request)}
                  >
                    Approve
                  </Button>
                </TableCell>
              </TableRow>
              )
            })}
          
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Pending