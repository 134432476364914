import ModalCloseButton from "../../../../components/ModalCloseButton"

import useModalContext from "../../../../hooks/contextHooks/useModalContext"
import useStyleContext from "../../../../hooks/contextHooks/useStyleContext"
import useBookingContext from "../../../../hooks/contextHooks/useBookingContext";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Modal, Typography, Button, Box, ButtonGroup, FormControl, Select, MenuItem, InputLabel, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import useAuthContext from '../../../../hooks/contextHooks/useAuthContext';
import useSubmitRequest from '../../../../hooks/otherHooks/booking-hooks/useSubmitRequest';
import useUpdateRequest from "../../../../hooks/otherHooks/booking-hooks/useUpdateRequest";
import Loading from "../../../../components/Loading"

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CustomModal from "../../../../components/CustomModal";


const UpdateRequestModal = (props) => {

  const fieldStyling = {
    margin: "0 0 10px 0",
    width:"100%"
  }

  const request = props.request

  const {modalStyle, primaryColor} = useStyleContext()
  const {updateRequestModal, setUpdateRequestModal, loading, setLoading} = useModalContext()

  const {authState} = useAuthContext()
  const {error, setError, success, setSuccess, updateRequest} = useUpdateRequest()
  const [selectedImage, setSelectedImage] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);
  const [imageBinary, setImageBinary] = useState(null)
  const [imageError, setImageError] = useState("")

  const [payMethod, setPayMethod] = useState("")
  const [payAmount, setPayAmount] = useState("")
  const [boothQty, setBoothQty] = useState(1)

  const handleChangePayMethod = (e) => {
    setPayMethod(e.target.value)
  }
  const handleChangePayAmount = (e) => {
    setPayAmount(e.target.value)
  }
  const handleChangeBoothQty = (e) => {
    setBoothQty(e.target.value)
  }
 

  const unselectedStyle = {
    color: primaryColor,
    borderColor: primaryColor,
    "&:hover": {
      color: primaryColor,
      borderColor: primaryColor
    }
  }

  const selectedStyle = {
    color: "white",
    borderColor: primaryColor,
    backgroundColor: primaryColor,
    "&:hover": {
      color: "white",
      backgroundColor: primaryColor,
      borderColor: primaryColor
    }
  }

  const [confirmationType, setConfirmationType] = useState()

  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setImageBinary(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
 }

  useEffect(() => {
    if (selectedImage) {
      getBase64(selectedImage)
      setImageUrl(URL.createObjectURL(selectedImage));
      setImageError("")
    }
  }, [selectedImage]);

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!selectedImage){
      setImageError("Please upload payment confirmation")
      return
    }

    // if (confirmationType == "Number" && e.target[5].value == ""){
    //   setImageError("Please enter payment confirmation number")
    //   return
    // }
    
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit"
    }

    const payDateUnformatted = new Date(e.target[6].value)

    const bookingInfo = {
      payMethod: payMethod,
      payAmount: payAmount,
      payDate: payDateUnformatted.toLocaleDateString("en-US", options),
      payConfirmation: imageBinary,
      vendorNotes: e.target[10].value,
      boothQty: boothQty
    }

    updateRequest(props.request._id, bookingInfo)
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const closeModal = () => {
    setUpdateRequestModal(false)
    sleep(300).then(() => {
      setError("")
      setSuccess(false)
      setSelectedImage(null)
      setImageUrl(null)
      setPayAmount("")
      setPayMethod("")
      setBoothQty(1)
    })
    // setConfirmationType(null)
  }

  return (

    <CustomModal
      open={updateRequestModal}
      onClose={closeModal}
    >
      <Box sx={modalStyle}>

      {loading ?
        <Loading 
          loadingText = "Updating request, do not click away from the page!"
        />
      : (
        success ? (
          <div style={{textAlign:"center"}}>
            <h4>Request Submitted!</h4>
            <Typography>
              Your request has been updated!
            </Typography>
            <CheckBoxRoundedIcon sx={{color: "green", fontSize: "200px", marginBottom: "10px"}} />
            <button 
              onClick={closeModal} 
              className="btn btn-danger" 
              style={{width:"100%"}}
            >
              Close
            </button>      
          </div>
  
        ) : (
          <>
          <Typography sx={{fontSize: "30px", color: primaryColor, textAlign:"center"}}>
            Update Request
          </Typography>
  
          <Typography sx={{fontSize: "20px",  textAlign:"center"}}>
            {props.request?.eventName}
          </Typography>
          
          <hr style={{margin: "15px 0 5px 0"}} />
  
          <Typography sx={{fontSize: "18px", color: primaryColor, textAlign:"center", fontWeight:"bold"}}>
            Current Submission
          </Typography>
  
          <hr style={{margin: "5px 0 5px 0"}} />
  
          <ul style={{marginTop:"10px"}}>
            <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Payment Method: </strong> {request?.payMethod}</li>
            <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Payment Amount: </strong> {"$" + request?.payAmount + ".00"}</li>
            <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Payment Date: </strong> {request?.payDate}</li>
            <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Booths Requested: </strong> {request?.boothQty}</li>
            <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Booking Notes: </strong> {request?.vendorNotes}</li>
            <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Payment Confirmation: </strong> {request?.payConfirmation.length < 1000 && request?.payConfirmation}</li>
          </ul>
  
          {request?.payConfirmation.length > 1000 && (
            <div style={{textAlign:"center", marginTop:"-15px"}}> 
              <img src={request?.payConfirmation} style={{maxWidth:"275px", margin:"5px 0 0 0"}}/>
            </div>
          )}
  
          <hr style={{margin: "15px 0 5px 0"}} />
  
          <Typography sx={{fontSize: "18px", color: primaryColor, textAlign:"center", fontWeight:"bold", margin:"10px 0 15px 0"}}>
            Update Submission
          </Typography>
            
            <form onSubmit={handleSubmit}>
  
            {error && 
              <Typography sx={{color: "red", marginBottom:"15px", fontSize:"15px", textAlign:"center"}}>
                {error}
              </Typography>
            }
  
              <div className="container">
  
                <div className="row g-2">
                  <div className="col-8">

                    <FormControl fullWidth>
                      <InputLabel id="boothQty">Payment Method</InputLabel>

                      <Select 
                        required
                        name = "payMethod" 
                        fullWidth variant="outlined" label="Payment Method" sx={fieldStyling}
                        value={payMethod}
                        onChange={handleChangePayMethod}
                      >
                        <MenuItem value = "Zelle">Zelle</MenuItem>
                        <MenuItem value = "Venmo">Venmo</MenuItem>
                        <MenuItem value = "PayPal">PayPal</MenuItem>
                        <MenuItem value = "Direct Deposit">Direct Deposit</MenuItem>
                        <MenuItem value = "Cash">Cash</MenuItem>
                      
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-4">
                    <FormControl fullWidth>
                      <InputLabel id="boothQty">Booths</InputLabel>

                      <Select 
                        required
                        name = "boothQty" 
                        fullWidth variant="outlined" label="Booths" sx={fieldStyling}
                        value={boothQty}
                        onChange={handleChangeBoothQty}
                      >
                        <MenuItem value = {1}>1</MenuItem>
                        <MenuItem value = {2}>2</MenuItem>
                        <MenuItem value = {3}>3</MenuItem>
                        <MenuItem value = {4}>4</MenuItem>
                        <MenuItem value = {5}>5</MenuItem>
                      
                      </Select>
                    </FormControl>

                  </div>
                </div>
  
                <div className="row g-2">
                  <div className="col-6">
                    <FormControl fullWidth>
                      <InputLabel id="paymentAmount">Paid Amount</InputLabel>

                      <Select 
                        required
                        name = "payAmount" 
                        fullWidth variant="outlined" label="Payment Amount" sx={fieldStyling}
                        value={payAmount}
                        onChange={handleChangePayAmount}
                      >
                        <MenuItem value = {225 * boothQty}>{"$" + 225 * boothQty}</MenuItem>
                        <MenuItem value = {250 * boothQty}>{"$" + 250 * boothQty}</MenuItem>
                        <MenuItem value = {275 * boothQty}>{"$" + 275 * boothQty}</MenuItem>
                        <MenuItem value = {300 * boothQty}>{"$" + 300 * boothQty}</MenuItem>
                        <MenuItem value = {325 * boothQty}>{"$" + 325 * boothQty}</MenuItem>
                        <MenuItem value = {350 * boothQty}>{"$" + 350 * boothQty}</MenuItem>
                        <MenuItem value = {375 * boothQty}>{"$" + 375 * boothQty}</MenuItem>
                        <MenuItem value = {400 * boothQty}>{"$" + 400 * boothQty}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-6">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker 
                        label="Pay Date" 
                      />
                    </LocalizationProvider>
                  </div>
                </div>
  
                {/* <div className="row g-0 mt-3" style={{textAlign:"center"}}> */}
                  {/* <Typography sx={{fontSize: "15px", margin:"0 0 0 3px", color:"#d9534f", textAlign:"left"}}>
                    Proof of Payment
                  </Typography> */}
                  {/* <div className="col">
                  <ButtonGroup variant="outlined" fullWidth >
                    <Button sx={confirmationType == "Picture" ? selectedStyle : unselectedStyle} onClick={() => setConfirmationType("Picture")}>Picture</Button>
                    <Button sx={confirmationType == "Number" ? selectedStyle : unselectedStyle} onClick={() => setConfirmationType("Number")}>Number</Button>
                  </ButtonGroup>
                  </div> */}
                {/* </div> */}
  
                <div className="row g-2 mt-1 mb-4">
                  {imageError && 
                    <Typography sx={{color:"red", fontSize:"14px", textAlign:"center"}}>
                      {imageError}
                    </Typography>
                  }
  
                  {/* {confirmationType == "Picture" &&  */}
                    <div className="col">
                      <Button variant="text" component="label" fullWidth sx={{textTransform: "none", color:"#d9534f"}}>
                        <PhotoCamera sx={{marginRight: "5px"}}/> 
                        {(imageUrl && selectedImage) ? "Reselect Proof of Payment" : "Upload Proof of Payment"}
                        <input 
                          hidden
                          name="confirmationImage" 
                          accept="image/*" 
                          type="file" 
                          onChange={e => setSelectedImage(e.target.files[0])}
                        />
                      </Button>
                      {imageUrl && selectedImage && (
                        <Box textAlign="center">
                          <img src={imageUrl} alt={selectedImage.name} height="100px" style={{margin:"10px 0 0 0"}}/>
                        </Box>
                      )}
                    </div>
                  {/* } */}
  
                  {/* {confirmationType == "Number" && 
                    <div className="col">
                      <TextField
                        id='confirmationNumber'
                        name="confirmationNumber"
                        label='Confirmation Number'
                        fullWidth
                      />
                    </div>
                  } */}
                  
                </div>

                <div className="row">
                  <div className="col">
                    <TextField 
                      fullWidth
                      multiline
                      rows={3}
                      label="Booking Notes (Optional)"
                      sx={{marginBottom: "20px"}}
                    />
                  </div>
                </div>
  
                <div className="row g-2">
                  <div className="col">
                    <Button 
                      type="submit" 
                      size="large"
                      variant="contained" 
                      fullWidth
                      sx={{textTransform: "none", backgroundColor:"#d9534f", color: "white", '&:hover': {backgroundColor: "#d44a46"}}}
                    >
                      Submit Request
                    </Button>
                  </div>
                </div>
  
                <div className="row g-2">
                  <div className="col">
                    <Button 
                      variant="outlined" 
                      size="large"
                      onClick={closeModal}
                      fullWidth
                      sx={{textTransform: "none", marginTop: "15px", color: "#d44a46", borderColor: "#d44a46", '&:hover': {backgroundColor: "#d44a46", borderColor:"#d44a46", color:"white"}}}
                    >
                      Cancel
                    </Button>
                  </div>
  
                </div>
  
              </div>
  
            </form>
          </> 
        )
      )}

    </Box>
  </CustomModal>

  )
}

export default UpdateRequestModal