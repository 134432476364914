import { Typography, Button } from "@mui/material"

const SoldOut = (props) => {

  const bookEvent = props.bookEvent

  return (
    <>
      <Typography sx={{fontSize: "30px",  textAlign:"center", color:"#d9534f"}}>
        {bookEvent?.name}
      </Typography>

      <Typography sx={{fontSize: "20px",  textAlign:"center"}}>
          {bookEvent?.date}
        </Typography>


        <hr style={{margin: "15px 0 15px 0"}} />

      <Typography sx={{fontSize: "20px",  textAlign:"center"}}>
        This event is SOLD OUT!
      </Typography>
      <Button 
        variant="contained" 
        size="large"
        onClick={props.closeModal}
        fullWidth
        sx={{marginTop: "20px", textTransform: "none", backgroundColor:"#d9534f", color: "white", '&:hover': {backgroundColor: "#d44a46"}}}
      >
        Close
      </Button>
    </>

  )
  
}

export default SoldOut