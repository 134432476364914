import useModalContext from "../../../hooks/contextHooks/useModalContext"
import useDeactivateUser from "../../../hooks/otherHooks/user-hooks/useDeactivateUser"
import { Modal, Box } from "@mui/material"
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import useStyleContext from "../../../hooks/contextHooks/useStyleContext";

const DeactivateUserModal = (props) => {

  const {modalStyle} = useStyleContext()

  const {deactivateUserModal, setDeactivateUserModal} = useModalContext()
  const {deactivateUser, error, success, setSuccess} = useDeactivateUser()

  const closeModal = () => {
    setDeactivateUserModal(false)
    setSuccess(false)
  }

  const handleConfirm = (user) => {
    deactivateUser(user)
  }
  
  return (
    <Modal
    open={props.open}
    onClose={props.onClose}
  >
    <Box sx={modalStyle}>
      {success ? 
        <Box textAlign="center">
          <h4>Successfully Deactivated User!</h4>
          <CheckBoxRoundedIcon sx={{color: "green", fontSize: "200px", marginBottom: "10px"}} />
          <button 
            onClick={closeModal} 
            className="btn btn-danger" 
            style={{width:"100%"}}
          >
            Close
          </button>
        </Box>
      :
        <>
          {error && 
            <h6 style={{color: "red", textAlign:"center"}}>{error}</h6>
          }
          <h3><strong>Are you sure you want to deactivate this user?</strong></h3>
          <hr/>
          <h6><strong>First Name: </strong>{props.user?.fName}</h6>
          <h6><strong>Last Name: </strong>{props.user?.lName}</h6>
          <h6><strong>Email: </strong>{props.user?.email}</h6>
          <h6><strong>Role: </strong>{props.user?.role}</h6>
          <hr/>
          <div className="row text-center mt-4">
            <div className="col">
              <button 
                onClick={() => handleConfirm(props.user)} 
                className="btn btn-danger" 
                style={{width:"100%"}}
              >
                Confirm
              </button>
            </div>
            <div className="col">
              <button 
                onClick={closeModal} 
                className="btn btn-outline-danger" 
                style={{width:"100%"}}
              >
                  Cancel
              </button>
            </div>
          </div>
        </>
      }
    </Box>
  </Modal>
  )
}

export default DeactivateUserModal