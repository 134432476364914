import { useState, useEffect } from "react"
import "./UpcomingEvents.css"
import useEventsContext from "../../../hooks/contextHooks/useEventsContext"
import useModalContext from "../../../hooks/contextHooks/useModalContext"
import BookingModal from "../../admin/booking-modal/BookingModal"
import { Typography, Button } from "@mui/material"
import useBookingContext from "../../../hooks/contextHooks/useBookingContext"
import { useNavigate } from "react-router-dom"
import useLogin from "../../../hooks/otherHooks/useLogin"

const UpcomingEvents = () => {

  const navigate = useNavigate()

  const headingStyle = {
    color: "#df4759",
    fontSize: "18px",
    fontWeight: "bold"
  }

  const rowStyle = {
    fontSize: "18px"
  }

  const {fetchEvents, events} = useEventsContext()
  const {setBookEvent} = useBookingContext()

  useEffect(()=> {
    fetchEvents()
  }, [])

  const handleBook = (event) => {
    navigate("/vendorportal")
  }

  return (
    
    <div className="container-fluid event-finder bg-danger" id="upcoming-events">
        
      <BookingModal/>

        <div className="row">
          <div className="col text-center">
            <h1><strong>Upcoming Events</strong></h1>
          </div>
        </div>


      <div className="container events">

        <div className="row">
          <div className="col-4 text-center">
            <Typography sx={headingStyle}>
              Event Date
            </Typography>
          </div>
          <div className="col-5 text-center">
            <Typography sx={headingStyle}>
              Event Name
            </Typography>
          </div>
          <div className="col-3 text-center">
            <Typography sx={headingStyle}>
              Actions
            </Typography>
          </div>
          
        </div>

          {events?.map(event => {
            return (
              <div className="row " key={event._id} style={{margin:"10px 0"}}>
                <hr style={{margin: "0 0 10px 0"}}/>
                <div className="col-4 text-center my-auto">
                  <Typography sx={rowStyle}>
                    {event.date}
                  </Typography>
                </div>
                <div className="col-5 text-center my-auto">
                  <Typography sx={rowStyle}>
                    {event.name}
                  </Typography>
                </div>
                <div className="col-3 text-center my-auto">
                  <Button variant="contained" size="small" sx={{margin: "5px" ,backgroundColor:"#df4759", '&:hover': {backgroundColor:"#d90d25"}}} onClick={() => window.open(event.link, '_blank', 'noreferrer')}>
                    More Info
                  </Button>

                  <Button
                    variant = "outlined"
                    size="small" 
                    sx={{margin: "5px" ,color:"#df4759", borderColor:"#df4759", '&:hover': {borderColor:"#d90d25"}}} onClick={() => handleBook(event)}
                  >
                    Book Now
                  </Button>

                </div>

              </div>
            )
          })}
          <hr style={{margin:"0"}}/>

      </div>
    </div>

  )
}

export default UpcomingEvents