import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import NavBar from "../../global/navbar/NavBar"
import useBookingContext from "../../../hooks/contextHooks/useBookingContext"
import { Typography, IconButton, Button } from "@mui/material"
import useEventsContext from "../../../hooks/contextHooks/useEventsContext"
import useStyleContext from "../../../hooks/contextHooks/useStyleContext"
import Approved from "./Approved"
import Pending from "./Pending"
import Denied from "./Denied"
import Cancelled from "./Cancelled"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useModalContext from "../../../hooks/contextHooks/useModalContext"
import DenyBookingModal from "../admin-overview/modals/DenyBookingModal"
import ApproveBookingModal from "../admin-overview/modals/ApproveBookingModal"
import ReviewBookingModal from "../admin-overview/modals/ReviewBookingModal"
import UpdateEventModal from "./modals/UpdateEventModal"

const EventDetails = () => {

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const {primaryColor} = useStyleContext()

  const {getBookingRequests, bookingRequests} = useBookingContext()
  const {getSingleEvent, singleEvent} = useEventsContext()

  useEffect(() => {
    getSingleEvent(searchParams.get("_id"))
  }, [bookingRequests])

  useEffect(() => {
    getBookingRequests()
  }, [])

  const { 
    setReviewBookingModal, 
    setApproveBookingModal,
    setDenyBookingModal,
    setUpdateEventModal
  } = useModalContext()

  const [booking, setBooking] = useState()
  const [event, setEvent] = useState()

  const openDenyBookingModal = (request) => {
    setBooking(request)
    setDenyBookingModal(true)
  }

  const openReviewBookingModal = (request) => {
    setBooking(request)
    setReviewBookingModal(true)
  }

  const openApproveBookingModal = (request) => {
    setBooking(request)
    setApproveBookingModal(true)
  }

  const openUpdateEventModal = (event) => {
    setEvent(event)
    setUpdateEventModal(true)
  }

  return (
    <>

      <NavBar
        admin = {true}
      />

      <DenyBookingModal
        booking = {booking}
      />

      <ReviewBookingModal 
        booking = {booking}
      />

      <ApproveBookingModal
        booking = {booking}
      />
      
      <UpdateEventModal 
        event = {event}
      />

      <div className="container" style={{margin: "140px auto 60px auto", textAlign:"center"}}>

          <div style={{}}>

          </div>

        <Typography sx={{fontSize: "30px", textAlign:"left"}}>

          <IconButton sx={{color:primaryColor, marginBottom:"5px"}} onClick={() => navigate("/admin")}>
            <ArrowBackIosNewIcon/>
          </IconButton>

          <strong style={{color:primaryColor, fontWeight:"normal"}}>{singleEvent?.name}</strong> - <strong style={{fontSize:"20px", fontWeight:"normal"}}>{singleEvent?.date}</strong>

          <Button 
            variant="outlined"
            size="small"
            onClick={() => openUpdateEventModal(singleEvent)}
            sx={{marginLeft: "20px", borderColor: "#d44a46", textTransform: "none", color: "#d44a46", '&:hover': {color: "white", backgroundColor: "#d44a46", borderColor:"#d44a46"}}}
          >
            Edit
          </Button>

        </Typography>

        <Typography sx={{fontSize: "20px", textAlign:"left"}}>
          Total Booths: {singleEvent?.boothsTotal} | Booked Booths: {singleEvent?.boothsBooked}
        </Typography>

        <div className="row">
          <div className="col-md-7">
            <div className="row mt-4">
              <Approved 
                eventID = {searchParams.get("_id")}
                requests = {bookingRequests}
                deny = {openDenyBookingModal}
              />
            </div>
            <hr style={{margin:"0"}}/>
          </div>

          <div className="col-md-5">
            <div className="row mt-4">
              <Pending 
                eventID = {searchParams.get("_id")}
                requests = {bookingRequests}
                review = {openReviewBookingModal}
                approve = {openApproveBookingModal}
                deny = {openDenyBookingModal}
              />
            </div>
            <hr style={{margin:"0"}}/>
            <div className="row mt-4">
              <Denied 
                eventID = {searchParams.get("_id")}
                requests = {bookingRequests}
                approve = {openApproveBookingModal}
              />
             
            </div>
            <hr style={{margin:"0"}}/>
            <div className="row mt-4">
              <Cancelled 
                eventID = {searchParams.get("_id")}
                requests = {bookingRequests}
              />
            </div>
            <hr style={{margin:"0"}}/>
          </div>
        </div>
      </div>




    
    </>

   )
}

export default EventDetails