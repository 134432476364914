import { Modal, Box, Typography, Button } from "@mui/material"
import ModalCloseButton from "../../../../components/ModalCloseButton"

import useModalContext from "../../../../hooks/contextHooks/useModalContext"
import useStyleContext from "../../../../hooks/contextHooks/useStyleContext"
import CustomModal from "../../../../components/CustomModal"

const BookingDetailsModal = (props) => {

  const booking = props.booking

  const {modalStyle, primaryColor} = useStyleContext()
  const {bookingDetailsModal, setBookingDetailsModal} = useModalContext()

  const closeModal = () => {
    setBookingDetailsModal(false)
  }
  
  return (
    <CustomModal
      open={bookingDetailsModal}
      onClose={closeModal}
    >
      <Box sx={modalStyle}>

        <Typography sx={{fontSize: "30px", color: primaryColor, textAlign:"center"}}>
          Booking Details
        </Typography>
        
        <Typography sx={{fontSize: "20px", textAlign:"center"}}>
          {booking?.eventName}
        </Typography>

        <hr style={{margin: "15px 0 5px 0"}} />

        <Typography sx={{fontSize: "18px", color: primaryColor, textAlign:"center"}}>
          Event Details
        </Typography>

        <hr style={{margin: "5px 0 5px 0"}} />

        <ul style={{marginTop:"10px"}}>
          <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Event Date: </strong> {booking?.eventDate}</li>
          <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Event Time: </strong> {booking?.startTime > 11 ? booking?.startTime + "pm" : booking?.startTime + "am"} - {booking?.endTime + "pm"}</li>
          <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Move In Time: </strong> {booking?.startTime - 2 + "am"} - {booking?.startTime > 11 ? booking?.startTime + "pm" : booking?.startTime + "am"}</li>
          <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Booth Details: </strong> You will be given a 10ft x 10ft booth and will be provided 1 table and 2 chairs.</li>
          <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Other: </strong> Please review Vendor Terms through the side menue for more detailed terms and conditions per event.</li>
        </ul>

        <Typography sx={{fontSize: "13px", fontStyle:"italic", color: primaryColor, textAlign:"left", marginBottom:"6px"}}>
          NOTE: Extra tables are not guranteed! Please bring your own if you feel you may need more than the 1 table that will be provided!
        </Typography>



        <hr style={{margin: "15px 0 5px 0"}} />

        <Typography sx={{fontSize: "18px", color: primaryColor, textAlign:"center"}}>
          Your Confirmed Request
        </Typography>

        <hr style={{margin: "5px 0 5px 0"}} />

        <ul style={{marginTop:"10px"}}>
          <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Booked On: </strong> {booking?.bookDate}</li>
          <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Payment Method: </strong> {booking?.payMethod}</li>
          <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Payment Date: </strong> {booking?.payDate}</li>
          <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Payment Amount: </strong> {booking?.payAmount}</li>
          <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Approved Date: </strong> {booking?.approveDate}</li>
          <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Notes: </strong> {booking?.notes}</li>
        </ul>



        <ModalCloseButton
          onClick = {closeModal}
        />
        
      </Box>
    </CustomModal>
  )

}

export default BookingDetailsModal