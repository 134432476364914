import { Modal, Box, TextField, Typography } from "@mui/material"
import useModalContext from "../../hooks/contextHooks/useModalContext"
import useStyleContext from "../../hooks/contextHooks/useStyleContext"
import ModalCloseButton from "../../components/ModalCloseButton"
import ModalPrimaryButton from "../../components/ModalPrimaryButton"
import useResetPassword from "../../hooks/otherHooks/useResetPassword"
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';


const ForgotPasswordModal = () => {
  
  const {forgotPasswordModal, setForgotPasswordModal} = useModalContext()
  const {modalStyle, primaryColor} = useStyleContext()
  const {resetPassword, error, setError, success, setSuccess} = useResetPassword()

  const closeModal = () => {
    setForgotPasswordModal(false)
    setSuccess(null)
    setError(null)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const email =  e.target[0].value
    resetPassword(email)
  }

  return (
    <Modal
      open = {forgotPasswordModal}
      onClose = {closeModal}
    >
      <Box sx={modalStyle}>

      {success ? 
        <Box textAlign="center">
          <h4>Please check your email for reset link, this link expires in 2 minutes</h4>
          <CheckBoxRoundedIcon sx={{color: "green", fontSize: "200px", marginBottom: "10px"}} />
          <ModalCloseButton
            onClick = {closeModal}
          />
        </Box>
        : 
          <>
            <Typography sx={{fontSize: "25px", color: primaryColor}}>
              Reset Password
            </Typography>

            <hr />

            <Typography sx={{fontSize: "15px",  textAlign:"left", marginBottom:"15px"}}>
              Please enter the email you used to sign up for your account.
            </Typography>


            <form onSubmit={handleSubmit}>
              {error && 
                <Typography sx={{color: "red", marginBottom:"15px", fontSize:"15px", textAlign:"center"}}>
                  {error}
                </Typography>
              }
              <TextField required type="email" fullWidth id="username" label="Email" variant="outlined" sx={{margin:"10px 0 20px"}}/>

              <ModalPrimaryButton
                type = "submit"
                buttonText = "Reset"
              />
            </form>
          
            <ModalCloseButton 
              onClick = {closeModal}
            />
          </>
        }
      </Box>
    </Modal>
  )
}

export default ForgotPasswordModal