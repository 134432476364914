import { useState } from "react"
import jwtDecode from "jwt-decode"
import useAuthContext from "../contextHooks/useAuthContext"

const useRegister = () => {

  const {authDispatch} = useAuthContext()

  const [error, setError] = useState()

  const register = async (registerInfo) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/register", {
      method: "POST",
      body: JSON.stringify(registerInfo),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await response.json()
    if (data.status == 200){
      const decoded = jwtDecode(data.token)
      const role = decoded.role
      localStorage.setItem("user", JSON.stringify(data))
      authDispatch({type:"LOGIN", payload:{role, ...data}})
    } else {
      setError(data.error)
    }
  }

  return {register, error}

}

export default useRegister