import { useState } from "react"

const useSignUp = () => {

  const [error, setError] = useState()
  const [success, setSuccess] = useState(false)

  const signup = async (signupInfo) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/addemail", {
      method: "POST",
      body: JSON.stringify(signupInfo),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await response.json()
    if (data.status == 200){
      setError(false)
      setSuccess(true)
    } else {
      setError(data.error)
    }
  }

  return {signup, error, success, setSuccess}

}

export default useSignUp