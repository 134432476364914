import { Box, Modal, Typography } from "@mui/material"
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import useModalContext from "../../../../hooks/contextHooks/useModalContext"
import useStyleContext from "../../../../hooks/contextHooks/useStyleContext"

import ModalPrimaryButton from "../../../../components/ModalPrimaryButton"
import ModalCloseButton from "../../../../components/ModalCloseButton"
import CustomModal from "../../../../components/CustomModal";


const CancelledDetailsModal = (props) => {


  const request = props.request

  const {modalStyle, primaryColor} = useStyleContext()
  const {cancelledDetailsModal, setCancelledDetailsModal} = useModalContext()

  const closeModal = () => {
    setCancelledDetailsModal(false)
  }
 
  return (
    <CustomModal
      open = {cancelledDetailsModal}
      onClose = {closeModal}
    >
      <Box sx={modalStyle}>
        <>
          <Typography sx={{fontSize: "30px", color: primaryColor, textAlign:"center"}}>
            Cancelled Request
          </Typography>

          <Typography sx={{fontSize: "20px", textAlign:"center"}}>
            {request?.eventName}
          </Typography>

          <Typography sx={{fontSize: "15px", textAlign:"center"}}>
            {request?.eventDate}
          </Typography>

          <hr style={{margin: "15px 0 5px 0"}} />

          <Typography sx={{fontSize: "18px", color: primaryColor, textAlign:"center"}}>
            Cancelled Submission
          </Typography>

          <hr style={{margin: "5px 0 5px 0"}} />

          <ul style={{marginTop:"10px"}}>
            <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Cancel Date: </strong> {request?.cancelDate}</li>
            <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Payment Method: </strong> {request?.payMethod}</li>
            <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Payment Amount: </strong> {request?.payAmount}</li>
            <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Payment Date: </strong> {request?.payDate}</li>
            <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>Payment Confirmation: </strong> {request?.payConfirmation.length < 1000 && request?.payConfirmation}</li>
          </ul>

          {request?.payConfirmation.length > 1000 && (
            <div style={{textAlign:"center", marginTop:"-15px"}}> 
              <img src={request?.payConfirmation} style={{maxWidth:"275px", margin:"5px 0 0 0"}}/>
            </div>
          )}

          <ModalCloseButton 
            onClick = {closeModal}
          />
        </>
        
      </Box>

    </CustomModal>
  )
}

export default CancelledDetailsModal