import { useContext } from "react";
import { UsersContext } from "../../context/UsersContext";

const useUsersContext = () => {

  const context = useContext(UsersContext)

  if (!context){
    throw Error("make sure component is wrapped inside the UsersContextProvider")
  }

  return context

}

export default useUsersContext