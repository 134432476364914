import { Button } from "@mui/material";

const ModalPrimaryButton = (props) => {
  
  return (
    <Button 
      type={props.type}
      onClick = {props.onClick}
      size="large"
      fullWidth
      variant="contained" 
      sx={{fontSize: props.fontSize, marginTop: "5px", textTransform: "none", backgroundColor:"#d9534f", color: "white", '&:hover': {backgroundColor: "#d44a46"}}}
    >
      {props.buttonText}
    </Button>
  )
}

export default ModalPrimaryButton