import { createContext, useReducer, useEffect } from "react"

export const MailChimpContext = createContext()

const citiesReducer = (cities, action) => {
  switch (action.type){
    case "SET":
      return action.payload
  }
}

export const MailChimpContextProvider = (props) => {

  const [cities, citiesDispatch] = useReducer(citiesReducer, [])

  const getCities = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/getcities")
    const data = await response.json()
    citiesDispatch({type: "SET", payload: data})
  }



  return (
    <MailChimpContext.Provider
      value={{
        cities,
        getCities
      }}
    >
      {props.children}
    </MailChimpContext.Provider>
  )
}