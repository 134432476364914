import { useState } from "react"
import useAuthContext from "../../contextHooks/useAuthContext"

const useGetUser = () => {

  const {authState} = useAuthContext()

  const [user, setUser] = useState()

  const getUser = async (id) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/getuser" + "/" + id, {
      headers: {
        "Authorization": `Bearer ${authState.token}`
      }
    })
    const data = await response.json()
    setUser(data.user)
  }

  return {getUser, user}
}

export default useGetUser