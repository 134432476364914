import { Box, Modal, Slide, Typography } from "@mui/material"
import useModalContext from "../../hooks/contextHooks/useModalContext"
import useStyleContext from "../../hooks/contextHooks/useStyleContext"
import ModalCloseButton from "../../components/ModalCloseButton"

const Terms = () => {

  const {modalStyle, primaryColor} = useStyleContext()

  return (
    <>
      <Slide direction="right" in={true} mountOnEnter unmountOnExit {...{ timeout: 500 }}>
        <Box>
          <hr style={{margin: "15px 0 5px 0"}} />

          <Typography sx={{fontSize: "18px", color: primaryColor, textAlign:"center"}}>
            Vendor Policy
          </Typography>

          <hr style={{margin: "5px 0 5px 0"}} />

          <ul style={{marginTop:"10px"}}>
            <li style={{fontSize:"13px"}}>Vendors will not advertise any other events on the day of the exhibition.</li>
            <li style={{fontSize:"13px"}}>Only one company/business is allowed per booth. If more than one business is discovered on the day of the event, a fine will be given to the vendor and/or their booth will be forfeited.</li>
            <li style={{fontSize:"13px"}}>ZN Fashions has the right to refuse any vendor for any reason at any given time.</li>
            <li style={{fontSize:"13px"}}>Vendors will not display exact pricing on any signage in their booths.</li>
          </ul>

          <hr style={{margin: "5px 0 5px 0"}} />

          <Typography sx={{fontSize: "18px", color: primaryColor, textAlign:"center"}}>
            Booth Policy
          </Typography>

          <hr style={{margin: "5px 0 5px 0"}} />

          <ul style={{marginTop:"10px"}}>
            <li style={{fontSize:"13px"}}>Each vendor will be provided with a 10ft x 10ft booth space on the day of the events they sign up for.</li>
            <li style={{fontSize:"13px"}}>Each vendor will be given one table and two chairs on the day of the event.</li>
            <li style={{fontSize:"13px"}}>Additional tables/chairs are not guranteed and may come with added charges on the day of the event.</li>
            <li style={{fontSize:"13px"}}>ZN Fashions reserves the right to asign vendors their designated booths per event.</li>
            <li style={{fontSize:"13px"}}>Vendors will not interfere with the neighboring booth or infringe on their area. Display must be kept within the confines of the contracted space.</li>
            <li style={{fontSize:"13px"}}>Each vendor will clean their booths after the event and leave the area as they found it. Failure to do so will result in a $50 fine.</li>
            <li style={{fontSize:"13px"}}>Each vendor assumes all risks and responsibilities for accidents, injuries or damages to person or property for the duration of each event they participate in.</li>
            <li style={{fontSize:"13px"}}>The vendor will not use adhesive/tape/nails/pins on the premises walls. If they do, they will be liable for all charges from event space and will face a minimum $50 penalty.</li>
          </ul>

          <hr style={{margin: "5px 0 5px 0"}} />

          <Typography sx={{fontSize: "18px", color: primaryColor, textAlign:"center"}}>
            Cancellation Policy
          </Typography>

          <hr style={{margin: "5px 0 5px 0"}} />


          <ul style={{marginTop:"10px"}}>
            <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>&lt; 7 days before event: </strong> no refund, no transfer of funds to other events</li>
            <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>&gt; 7 days before event: </strong> no refund, can transfer funds to other events</li>
            <li style={{fontSize:"13px"}}><strong style={{color:primaryColor}}>&gt; 30 day before event:</strong> refund available or transfer funds to other events</li>
          </ul>

          <Typography sx={{fontSize: "13px", fontStyle:"italic", color: primaryColor, textAlign:"left", marginBottom:"6px"}}>
            NOTE: We will coordinate refunds/transferring funds to other events through the email sent to you once you confirm the cancellation of a confirmed request.
          </Typography>

        </Box>
      </Slide>
        

    </>
  )
}
export default Terms