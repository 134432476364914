import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const useAuthContext = () => {

  const context = useContext(AuthContext)

  if (!context){
    throw Error("make sure component is wrapped inside the AuthContextProvider")
  }

  return context
}

export default useAuthContext