import * as React from 'react';
import useModalContext from '../../../hooks/contextHooks/useModalContext';
import useStyleContext from '../../../hooks/contextHooks/useStyleContext';
import useEventsContext from '../../../hooks/contextHooks/useEventsContext';
import useBookingContext from '../../../hooks/contextHooks/useBookingContext';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, Tooltip, Slide, Box } from '@mui/material';
import Loading from '../../../components/Loading';

const SoldOutEvents = (props) => {

  const {setBookEvent} = useBookingContext()
  const {setBookingModal, bookingModal} = useModalContext()
  const {primaryColor, headingStyle, rowStyle} = useStyleContext()
  const {getUnbookedEvents, unbookedEvents} = useEventsContext()
  const {checkAvailableBooths} = useBookingContext()


  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit"
  }

  const convertDate = (date) => {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + 1)
    const convertedDate = newDate.toLocaleDateString("en-US", options)
    return convertedDate
  }

    return (
      <>
        {!props.unbookedEvents ? <Loading loadingText = "Loading sold out events..."/> : ( 
          <Box>
            <TableContainer component={Paper} sx={{maxHeight: "500px"}}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow sx={{backgroundColor: primaryColor}}>
                    <TableCell sx={headingStyle}>Name</TableCell>
                    <TableCell sx={headingStyle}>Date</TableCell>
                    <TableCell sx={headingStyle}>Price</TableCell>
                    <TableCell sx={headingStyle} align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  
                  {props.unbookedEvents?.map(event => {
                    if (event.boothsTotal - event.boothsBooked <= 0) {
                      return(
                        <TableRow key={event._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row" sx={rowStyle}>{event.name.replace("ZN Fashions ", "")}</TableCell>
                          <TableCell sx={rowStyle}>{convertDate(event.sortDate)}</TableCell>
                          <TableCell sx={rowStyle}>{"$" + event.boothPrice}</TableCell>
                          <TableCell align="center">
                            <Button 
                              variant='contained'
                              size='small'
                              sx={{fontSize: "12px", backgroundColor:primaryColor, textTransform:"none", '&:hover': {backgroundColor: "#d44a46"}}} 
                              disabled = {true}
                            >
                              Sold
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  })}
                
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
       
      </>
    )
}

export default SoldOutEvents