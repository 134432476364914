import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { Typography, Button, Tooltip } from '@mui/material';
import useStyleContext from "../../../../hooks/contextHooks/useStyleContext"
import useModalContext from '../../../../hooks/contextHooks/useModalContext';
import CancelConfirmedModal from "../modals/CancelConfirmedModal"
import BookingDetailsModal from "../modals/BookingDetailsModal"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Loading from '../../../../components/Loading';

import Slide from '@mui/material/Slide';


const ConfirmedEvents = (props) => {

  const {primaryColor, headingStyle, rowStyle} = useStyleContext()
  const {setCancelConfirmedModal, setBookingDetailsModal} = useModalContext()
  const [cancelRequest, setCancelRequest] = useState()
  const [bookingDetails, setBookingDetails] = useState()

  const checkDate = (date) => {
    const today = new Date()
    today.setHours(0,0,0,0)
    const eventDate = new Date(date)
    if (eventDate > today) {
      return true
    } else {
      return false
    }
  }

  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit"
  }

  const convertDate = (date) => {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + 1)
    const convertedDate = newDate.toLocaleDateString("en-US", options)
    return convertedDate
  }

  const openCancelConfirmedModal = (request) => {
    setCancelRequest(request)
    setCancelConfirmedModal(true)
  }

  const openDetailsModal = (request) => {
    setBookingDetails(request)
    setBookingDetailsModal(true)
  }

  const toolTipText1 = "Confirmed Requests are requests you submitted that have been approved by the booking team. Click 'Details' on any confirmed event to see things like move in and move out time."
  const toolTipText2 = 'To submit a new booking request, please visit the "Book Event" tab of this portal.'


  return (
    <>
      <CancelConfirmedModal
        request = {cancelRequest}
      />

      <BookingDetailsModal
        booking = {bookingDetails}
      />

        <Typography sx={{fontSize: "20px",  marginBottom: "10px", textAlign:"left"}}>
          Confirmed Requests
          <Tooltip title={toolTipText1} enterTouchDelay={0}>
            <HelpOutlineIcon sx={{color:"gray", margin:"0 0 1px 2px", fontSize:"19px"}}/>
          </Tooltip>
        </Typography>

        <hr />    

      {!props.requests ? <Loading loadingText = "Loading your confirmed events..."/> : ( 
        <>
          {props.requests?.filter(request => request.payStatus == "Approved").length == 0 &&

            <div style={{textAlign:"center", margin:"10px"}}>
              <Typography fontSize="13px">
                <span style={{color:primaryColor, fontSize:"25px", fontWeight:"bold"}}>
                  No confirmed requests!
                </span>
                <br />
                <Tooltip title={toolTipText2} enterTouchDelay={0}>
                  <HelpOutlineIcon sx={{color:"gray", margin:"0 0 20px 0", fontSize:"17px"}}/>
                </Tooltip>
              </Typography>
              <Typography fontSize="13px">
                For questions, please email: <span style={{color:primaryColor, fontWeight:"bold"}}>booking@znfashions.com.</span>
              </Typography>
            </div>
            
          }

          <TableContainer component={Paper} sx={{maxHeight: "500px"}}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow sx={{backgroundColor: primaryColor}}>
                  <TableCell sx={headingStyle}>Event Name</TableCell>
                  <TableCell sx={headingStyle}>Event Date</TableCell>
                  <TableCell sx={headingStyle} align="center">Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {props.requests?.map(request => {
                  if (request.payStatus == "Approved") {
                    return (
                      <TableRow
                      key={request._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" sx={rowStyle}>
                        {request.eventName.replace("ZN Fashions ", "")}
                      </TableCell>
                      <TableCell sx={rowStyle}>{convertDate(request.sortDate)}</TableCell>
                      <TableCell align="center" sx={rowStyle}>
                        {checkDate(request.sortDate) && 
                          <Button variant='text' size='small' sx={{color:"red", textTransform:"none", fontSize:"12px"}} onClick = {() => openCancelConfirmedModal(request)}>
                            Cancel
                          </Button>
                        }
                        <Button variant='text' size='small' sx={{color:"grey", textTransform:"none", fontSize:"12px"}} onClick = {() => openDetailsModal(request)}>
                          Details
                        </Button>
                      </TableCell>
                    </TableRow>
                    )
                  }
                })}

              
              </TableBody>
            </Table>
          </TableContainer>

        </>
      )}

      
    
    </>
  )
}

export default ConfirmedEvents