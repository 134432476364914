import { useState } from "react"


const useResetPassword = () => {

  const [error, setError] = useState()
  const [success, setSuccess] = useState()

  const resetPassword = async (email) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/resetpassword", {
      method: "POST",
      body: JSON.stringify({email}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await response.json()
    if (data.status == 200){
      setSuccess(true)
    } else {
      setError(data.error)
    }
  }

  return {resetPassword, success, setSuccess, error, setError}

} 

export default useResetPassword