import { Box, Modal, Typography } from "@mui/material"
import useModalContext from "../../hooks/contextHooks/useModalContext"
import useStyleContext from "../../hooks/contextHooks/useStyleContext"
import ModalCloseButton from "../../components/ModalCloseButton"
import Terms from "./Terms"

const TermsModal = () => {
  
  const {modalStyle, primaryColor} = useStyleContext()
  const {termsModal, setTermsModal} = useModalContext()

  const closeModal = () => {
    setTermsModal(false)
  }

  return (
    <Modal
      open = {termsModal}
      onClose = {closeModal}
    >
      <Box sx={modalStyle}>

        <Typography sx={{fontSize: "30px", color: primaryColor, textAlign:"center"}}>
          ZN Fashions Inc.
        </Typography>

        <Typography sx={{fontSize: "20px", color: primaryColor, textAlign:"center"}}>
          Vendor Terms and Conditions
        </Typography>


        <Terms />



        <ModalCloseButton 
          onClick = {closeModal}
        />
      </Box>
    </Modal>
  )
}

export default TermsModal