import "./AboutUs.css"

import chevron from "./icons8-chevron-100.png"
import dollar from "./icons8-dollar-coin-100.png"
import family from "./icons8-family-100.png"
import location from "./icons8-visit-100.png"

const AboutUs = () => {

  return (
    <>
      <div id="about-us"></div>

      <div className="container-fluid about-us">

          <div className="container">

              <div className="row about-title">
                  <div className="col">
                      <h1><strong>About Us</strong></h1>
                  </div>
              </div>

              <div className="baloons"></div>

              <hr/>

              <div className="row baloon-description">
                  <div className="col">
                      <h1>ZN Fashions is a US-based exhibition company, specializing in Pakistani and Indian clothing and jewelry.</h1>
                  </div>
              </div>

              <div className="row about-row-1">

                  <div className="col-md-1">
                      <img src={family} className="img-thumbnail" alt=""/>
                  </div>

                  <div className="col-md-5">
                      <h2><strong>Family Owned and Operated</strong></h2>
                      <p>Since our inception in 2010, ZN Fashions remains
                          solely operated by the same family. No franchisees,
                          no funny business, you’ll see us at every event.</p>
                  </div>
                  <div className="col-md-1">
                      <img src={location} className="img-thumbnail" alt=""/>
                  </div>
                  <div className="col-md-5" >
                      <h2><strong>60+ Events in 30+ Cities</strong></h2>
                      <p>ZN Fashions remains the largest South-Asian exhibition
                          organizers in the entire country, we pride ourselves
                          in finding and developing new markets.</p>
                  </div>

              </div>

              <div className="row about-row-2">
                  <div className="col-md-1" id="icon-container" data-aos="fade-right">
                      <img src={chevron} className="img-thumbnail" alt=""/>
                  </div>
                  <div className="col-md-5" data-aos="fade-right">
                      <h2><strong>The Highest Standards</strong></h2>
                      <p>ZN Fashions strictly monitors the quality of each of
                          the 500+ vendors we work with across the world.
                          We do not settle for low quality, ever.</p>
                  </div>
                  <div className="col-md-1" id="icon-container" data-aos="fade-right">
                      <img src={dollar} className="img-thumbnail" alt=""/>
                  </div>
                  <div className="col-md-5" data-aos="fade-right">
                      <h2><strong>Supporting Local Business</strong></h2>
                      <p>No matter the market, ZN Fashions prides itself in being
                          able to creating a platform for small businesses to sell 
                          their products and services.</p>
                  </div>
              </div>

              <div className="row">
                  <div className="col">

                  </div>
              </div>

          </div>

      </div>
    </>
  )
}

export default AboutUs