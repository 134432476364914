import { createContext, useReducer, useEffect } from "react";
import jwtDecode from "jwt-decode"

export const AuthContext = createContext()

const authReducer = (authState, action) => {
  switch (action.type){
    case "LOGIN":
      return action.payload
    case "LOGOUT":
      return null
  }
}

export const AuthContextProvider = (props) => {

  const [authState, authDispatch] = useReducer(authReducer, null)

  const checkAuth = async () => {
    const user = JSON.parse(localStorage.getItem("user"))
    if (user){
      const isValid = await checkToken({token: user.token})
      if (isValid){
        const decoded = jwtDecode(user.token)
        const role = decoded.role
        authDispatch({type:"LOGIN", payload:{role, ...user}})
      } else {
        localStorage.removeItem("user")
        authDispatch({type:"LOGOUT"})
      }
    } else {
      localStorage.removeItem("user")
      authDispatch({type:"LOGOUT"})
    }
  }

  useEffect(() => {

    checkAuth()
   
  }, [])

  const checkToken = async (token) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/checktoken", {
      method: "POST",
      body: JSON.stringify(token),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await response.json()
    return data.valid
  }

  return (
    <AuthContext.Provider
      value = {{
        authState,
        authDispatch,
        checkAuth
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}