import { createContext, useState } from "react";

export const ModalContext = createContext()

const ModalContextProvider = (props) => {

  const [detailsEventModal, setDetailsEventModal] = useState(false)
  const [deleteEventModal, setDeleteEventModal] = useState(false)
  const [addEventModal, setAddEventModal] = useState(false)

  const [deactivateUserModal, setDeactivateUserModal] = useState(false)
  const [addUserModal, setAddUserModal] = useState(false)
  const [detailsUserModal, setDetailsUserModal] = useState(false)

  // Admin Portal
  const [bookingModal, setBookingModal] = useState(false)
  const [reviewBookingModal, setReviewBookingModal] = useState(false)
  const [approveBookingModal, setApproveBookingModal] = useState(false)
  const [denyBookingModal, setDenyBookingModal] = useState(false)
  const [addBookingModal, setAddBookingModal] = useState(false)
  const [viewImageModal, setViewImageModal] = useState(false)

  // Vendor Portal
  const [cancelConfirmedModal, setCancelConfirmedModal] = useState(false)
  const [cancelPendingModal, setCancelPendingModal] = useState(false)

  // Register Form
  const [termsModal, setTermsModal] = useState(false)

  // Login Form
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false)

  const [profileModal, setProfileModal] = useState(false)

  const [loading, setLoading] = useState(false)

  const [bookingDetailsModal, setBookingDetailsModal] = useState(false)
  const [deniedDetailsModal, setDeniedDetailsModal] = useState(false)
  const [cancelledDetailsModal, setCancelledDetailsModal] = useState(false) 
  const [updateRequestModal, setUpdateRequestModal] = useState(false)
  const [updateEventModal, setUpdateEventModal] = useState(false)

  const [confirmLogoutModal, setConfirmLogoutModal] = useState(false)
 
  return (
    <ModalContext.Provider
    value = {{
      detailsEventModal,
      setDetailsEventModal,
      deleteEventModal,
      setDeleteEventModal,
      addEventModal,
      setAddEventModal,
      deactivateUserModal,
      setDeactivateUserModal,
      addUserModal,
      setAddUserModal,
      detailsUserModal,
      setDetailsUserModal,
      bookingModal,
      setBookingModal,
      loading, 
      setLoading,
      reviewBookingModal,
      setReviewBookingModal,
      approveBookingModal,
      setApproveBookingModal,
      denyBookingModal,
      setDenyBookingModal,
      addBookingModal,
      setAddBookingModal,
      viewImageModal,
      setViewImageModal,
      cancelConfirmedModal,
      setCancelConfirmedModal,
      cancelPendingModal,
      setCancelPendingModal,
      termsModal,
      setTermsModal,
      forgotPasswordModal,
      setForgotPasswordModal,
      profileModal,
      setProfileModal,
      bookingDetailsModal,
      setBookingDetailsModal,
      updateRequestModal,
      setUpdateRequestModal,
      deniedDetailsModal,
      setDeniedDetailsModal,
      cancelledDetailsModal,
      setCancelledDetailsModal,
      confirmLogoutModal,
      setConfirmLogoutModal,
      updateEventModal,
      setUpdateEventModal
    }}
    >
      {props.children}
    </ModalContext.Provider>
  )

}

export default ModalContextProvider