import { createContext, useReducer, useEffect, useState } from "react";
import useAuthContext from "../hooks/contextHooks/useAuthContext";

export const EventsContext = createContext()

export const EventsContextProvider = (props) => {

  const {authState} = useAuthContext()

  // used in home page
  const [events, setEvents] = useState()
  const fetchEvents = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/getevents")
    const data = await response.json()
    setEvents(data.events)
  }

  // used in admin panel
  const [eventDetails, setEventDetails] = useState()
  const fetchEventDetails = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/geteventdetails", {
      headers: {
        "Authorization": `Bearer ${authState.token}`
      }
    })
    const data = await response.json()
    setEventDetails(data.events)
  }

  // used in admin panel event details
  const [singleEvent, setSingleEvent] = useState()
  const getSingleEvent = async (id) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/getsingleevent/" + id, {
      headers: {
        "Authorization": `Bearer ${authState.token}`
      }
    })
    const data = await response.json()
    setSingleEvent(data.event)
  }

  // used in vendor portal
  const [unbookedEvents, setUnbookedEvents] = useState()
  const getUnbookedEvents = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/getunbookedevents", {
      method: "POST",
      body: JSON.stringify({id:authState.id}),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authState.token}`
      }
    })
    const data = await response.json()
    if (data.status === 200){
      setUnbookedEvents(data.unbookedEvents)
    } else {
      console.log(data.error)
    }
  }

  return(
    <EventsContext.Provider
      value = {{
        events,
        fetchEvents,

        eventDetails,
        fetchEventDetails,
        setEventDetails,

        unbookedEvents,
        setUnbookedEvents,
        getUnbookedEvents,

        singleEvent,
        getSingleEvent,
        setSingleEvent
      }}
    >
      {props.children}
    </EventsContext.Provider>
  )
}