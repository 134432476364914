import { useContext } from "react";
import { StyleContext } from "../../context/StyleContext";

const useStyleContext = () => {

  const context = useContext(StyleContext)

  if (!context){
    throw Error("make sure component is wrapped inside the StyleContextProvider")
  }

  return context

}

export default useStyleContext