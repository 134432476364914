import { Routes, Route, useNavigate, useLocation } from "react-router-dom"
import Landing from "./scenes/landing/Landing"
import Login from "./scenes/login/Login";
import AdminPanel from "./scenes/admin/AdminPanel";

import useAuthContext from "./hooks/contextHooks/useAuthContext"
import VendorPortal from "./scenes/vendor-portal/VendorPortal";
import Register from "./scenes/register/Register";
import ResetPassword from "./scenes/login/ResetPassword";
import EventDetails from "./scenes/admin/event-details/EventDetails";
import ProfilePage from "./scenes/profile/ProfilePage";
import { useEffect } from "react";

const App = () => {

  const {authState} = useAuthContext()

  useEffect(() => {
   const url = window.location.href
   if (url.includes("http://") && url.includes("znfashions.com"))  {
    const https_url = url.replace("http://", "https://")
    window.location.replace(https_url)
   }
  }, [])

  return (
    <Routes>
      <Route path="/" element={<Landing/>}/>
      
      <Route path="/login" element={
        authState?.role == "admin" ? <AdminPanel/> 
        : (authState?.role == "vendor" ? <VendorPortal/> 
        : <Login/>)
      }/>

      <Route path="/vendorportal" element={
        authState?.role == "admin" ? <AdminPanel/> 
        : (authState?.role == "vendor" ? <VendorPortal/> 
        : <Login/>)
      }/>

      <Route path="/register" element={
        authState?.role == "admin" ? <AdminPanel/> 
        : (authState?.role == "vendor" ? <VendorPortal/> 
        : <Register/>)
      }/>

      <Route path="/admin" element={
        authState?.role == "admin" ? <AdminPanel/> 
        : (authState?.role == "vendor" ? <VendorPortal/> 
        : <Login/>)
      }/>

      <Route path="/admin/eventdetails" element={
        authState?.role == "admin" ? <EventDetails/> 
        : (authState?.role == "vendor" ? <VendorPortal/> 
        : <Login/>)
      }/>

      <Route path="/resetpassword" element={
        authState?.role == "admin" ? <AdminPanel/> 
        : (authState?.role == "vendor" ? <VendorPortal/> 
        : <ResetPassword/>)
      }/>

      <Route path="/profile" element={
        authState?.role == "admin" ? <ProfilePage/> 
        : (authState?.role == "vendor" ? <ProfilePage/> 
        : <Login/>)
      }/>



    </Routes>
  );
}

export default App;
