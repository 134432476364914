import { useState } from "react"
import useAuthContext from "../../contextHooks/useAuthContext"
import useUsersContext from "../../contextHooks/useUsersContext"

const useDeactivateUser = () => {

  const {authState} = useAuthContext()
  const {setUsers} = useUsersContext()

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState("")

  const deactivateUser = async (user) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/deactivateuser", {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authState.token}`
      }
    })
    const data = await response.json()
    if (data.status === 200){
      setSuccess(true)
      setUsers(data.users)
    } else {
      setError(data.error)
    }
  }

  return {deactivateUser, success, setSuccess, error}
}

export default useDeactivateUser