import { useEffect, useState } from "react";
import {Modal, Box, Button, Typography, CircularProgress, Grow, backdropClasses, Backdrop, Fade} from "@mui/material"
import useModalContext from "../../../hooks/contextHooks/useModalContext";
import useBookingContext from "../../../hooks/contextHooks/useBookingContext";
import useAuthContext from "../../../hooks/contextHooks/useAuthContext";
import useStyleContext from "../../../hooks/contextHooks/useStyleContext"
import useSubmitRequest from "../../../hooks/otherHooks/booking-hooks/useSubmitRequest";
import SoldOut from "./SoldOut";
import BookingForm from "./BookingForm";
import BookingSuccess from "./BookingSuccess";
import Loading from "../../../components/Loading";
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CustomModal from "../../../components/CustomModal";


const BookingModal = (props) => {

  const {modalStyle, primaryColor} = useStyleContext()
  const {authState} = useAuthContext()
  const {bookEvent, setBookEvent} = useBookingContext()
  const {success, setSuccess, submitRequest} = useSubmitRequest()
  const {boothsAvailable} = useBookingContext()
  const {bookingModal, setBookingModal, loading, setLoading} = useModalContext()

  const closeModal = () => {
    setBookingModal(false)
    setSuccess(false)
    setLoading(false)
  }

  return (
    <CustomModal
      open={bookingModal}
      onClose={closeModal}
    >
        <Box sx={modalStyle}>
          {loading ? (
              <Loading 
                loadingText = "Submitting request, do not click off page!"
              />
            ) :
            (authState &&
              (boothsAvailable ? 
                (success ?
                  <div style={{textAlign:"center"}}>
                    <h4>Request Submitted!</h4>
                    <Typography>
                      Please check your email for confirmation. If approved, we will send another email with more details!
                    </Typography>
                    <CheckBoxRoundedIcon sx={{color: "green", fontSize: "200px", marginBottom: "10px"}} />
                    <button 
                      onClick={closeModal} 
                      className="btn btn-danger" 
                      style={{width:"100%"}}
                    >
                      Close
                    </button>
                  </div>
                  : 
                  <BookingForm
                    bookEvent = {bookEvent}
                    setSuccess = {setSuccess}
                    submitRequest = {submitRequest}
                  />
                ) : (
                  <SoldOut
                    bookEvent = {bookEvent}
                    closeModal = {closeModal}
                  />
                )
              ) 
            )}

        </Box>
    </CustomModal>
  );
}

export default BookingModal