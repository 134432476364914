import "./EventAlerts.css"
import PinkCard from "../../../components/pink-card/PinkCard"
import { useEffect, useState } from "react"
import useSignUp from "../../../hooks/otherHooks/useSignUp"
import { Modal, Box } from "@mui/material"
import useMailChimpContext from "../../../hooks/contextHooks/useMailChimpContext"

const style = {
  textAlign:"center",
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: "25px"
};


const SignUp = () => {

  const {signup, success, error, setSuccess} = useSignUp()
  const {cities, getCities} = useMailChimpContext()

  useEffect(() => {
    getCities()
  }, [])

  const submitForm = (e) => {
    e.preventDefault()
    const signupInfo = {
      fName: e.target[0].value,
      lName: e.target[1].value,
      listID: e.target[2].value,
      email: e.target[3].value,
    }
    signup(signupInfo)
  } 

  const closeModal = () => {
    setSuccess(false)
  }

  return (
    <div className="container email">
      <div className="row">
        <div className="col-md-8">
          <h1><strong>Subscibe to Event Alerts!</strong></h1>
          <hr />
          <p>We strive to notify our customers of any upcoming events at least a week in advanced. Sign up to receive emails about events near your city!</p>
        </div>

        <Modal
          open={success}
          onClose={closeModal}
        >
          <Box sx={style}>
            <h1 style={{color:"green", fontWeight: "bold"}}>Success!</h1>
            <hr />
            <p>Change cities and re-submit if you would like to be added to more lists!</p>
            <hr />
            <button 
              onClick={closeModal} 
              className="btn btn-success mt-2" 
              style={{width:"100%"}}
            >
              Close
            </button>
          </Box>
        </Modal>

        <div className="col-md-4 signup-form" >
          <PinkCard>
            <p><strong>Subscribe:</strong></p>

            {error && <h6 style={{color:"red", textAlign:"center"}}>{error}</h6>}
            <form onSubmit={submitForm}>
              <div className="form-floating mb-3">
                <input type="input" className="form-control" name="fName" id="fName" placeholder="First Name" required/>
                <label htmlFor="fName">First Name</label>
              </div>
              <div className="form-floating mb-3">
                <input type="input" className="form-control" name="lName" id="lName" placeholder="Last Name" required/>
                <label htmlFor="lName">Last Name</label>
              </div>
                <select className="form-select mb-3" name="cityName" required>
                    {cities?.map((city) => {
                      return (<option key={city.id} value={city.id}>{city.name}</option>)
                    })}
                </select>
              <div className="form-floating mb-3">
                <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" required/>
                <label htmlFor="email">Email</label>
              </div>
              <div className="d-grid">
                <button className="btn btn-danger" type="submit"><strong>Submit</strong></button>
              </div>
            </form>
          </PinkCard>
        </div>
      </div>
    </div>
  )
}

export default SignUp