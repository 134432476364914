import {Modal, Box, Button, Typography, CircularProgress} from "@mui/material"
import useStyleContext from "../hooks/contextHooks/useStyleContext"

const Loading = (props) => {

  const {primaryColor} = useStyleContext()

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography mb={3}>
        {props.loadingText}
      </Typography>
      <CircularProgress sx={{color:primaryColor}}/>
    </Box>
  )
}

export default Loading