import { Modal, Box, Typography, Button } from "@mui/material"
import ModalCloseButton from "../../../../components/ModalCloseButton";
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

import useModalContext from "../../../../hooks/contextHooks/useModalContext";
import useStyleContext from "../../../../hooks/contextHooks/useStyleContext";
import ModalPrimaryButton from "../../../../components/ModalPrimaryButton";
import useApproveRequest from "../../../../hooks/otherHooks/booking-hooks/useApproveRequest"
import Loading from "../../../../components/Loading";

const ApproveBookingModal = (props) => {

  const {modalStyle, primaryColor} = useStyleContext()
  const {approveBookingModal, setApproveBookingModal, loading, setLoading} = useModalContext()
  const {approveRequest, success, error, setSuccess, setError} = useApproveRequest()

  const closeModal = () => {
    setApproveBookingModal(false)
    setError(null)
    setSuccess(null)
  }

  const approveBooking = async () => {
    setLoading(true)
    await approveRequest(props.booking)
    setLoading(false)
  }

  return (
    <Modal
      open={approveBookingModal}
      onClose={closeModal}
    >
      <Box sx={modalStyle}>
      {
        loading ? <Loading loadingText = "Approving Request..."/> : (
          success ? 
            <Box textAlign="center">
              <h4>Successfully Approved Booking Request!</h4>
              <CheckBoxRoundedIcon sx={{color: "green", fontSize: "200px", marginBottom: "10px"}} />
              <ModalCloseButton
                onClick = {closeModal}
              />
            </Box>
          : 
            <>
              <Typography sx={{fontSize: "25px", color: "green"}}>
                Are you sure you want to APPROVE this booking?
              </Typography>
  
              <hr />
  
  
              {error && 
                <Typography sx={{color: "red", marginBottom:"15px", fontSize:"15px", textAlign:"center"}}>
                  {error}
                </Typography>
              }
  
              <Typography sx={{marginBottom: "15px"}}>
                The user will be sent a confirmation email once "Approve" button is clicked.
              </Typography>
  
              <ModalPrimaryButton
                buttonText = "Approve"
                onClick = {approveBooking}
              />
              
              <ModalCloseButton
                onClick = {closeModal}
              />
            
            </>
        )
      }
     
      </Box>
    </Modal>
  )

}

export default ApproveBookingModal