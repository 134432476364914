import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Typography, Button, Box, ButtonGroup, FormControl, Select, MenuItem, InputLabel, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import useAuthContext from '../../../hooks/contextHooks/useAuthContext';
import useSubmitRequest from '../../../hooks/otherHooks/booking-hooks/useSubmitRequest';
import useModalContext from '../../../hooks/contextHooks/useModalContext';
import useBookingContext from '../../../hooks/contextHooks/useBookingContext';
import useStyleContext from '../../../hooks/contextHooks/useStyleContext';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const BookingForm = (props) => {

  const setSuccess = props.setSuccess
  const submitRequest = props.submitRequest

  const {bookEvent, setBookEvent, checkAvailableBooths} = useBookingContext()
  const {authState} = useAuthContext()
  const {error, setError} = useSubmitRequest()
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageBinary, setImageBinary] = useState(null)
  const [imageError, setImageError] = useState("")
  const {setBookingModal, loading, setLoading} = useModalContext()
  const {primaryColor}= useStyleContext()

  const [payMethod, setPayMethod] = useState("")
  const [payAmount, setPayAmount] = useState("")
  const [boothQty, setBoothQty] = useState(1)

  const handleChangePayMethod = (e) => {
    setPayMethod(e.target.value)
  }
  const handleChangePayAmount = (e) => {
    setPayAmount(e.target.value)
  }
  const handleChangeBoothQty = (e) => {
    setBoothQty(e.target.value)
  }
 
  const fieldStyling = {
    margin: "0 0 15px 0",
    width:"100%"
  }

  const unselectedStyle = {
    color: primaryColor,
    borderColor: primaryColor,
    "&:hover": {
      color: primaryColor,
      borderColor: primaryColor
    }
  }

  const selectedStyle = {
    color: "white",
    borderColor: primaryColor,
    backgroundColor: primaryColor,
    "&:hover": {
      color: "white",
      backgroundColor: primaryColor,
      borderColor: primaryColor
    }
  }

  const [confirmationType, setConfirmationType] = useState()

  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setImageBinary(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
 }

  useEffect(() => {
    if (selectedImage) {
      getBase64(selectedImage)
      setImageUrl(URL.createObjectURL(selectedImage));
      setImageError("")
    }
  }, [selectedImage]);

  const handleSubmit = async (e) => {

    e.preventDefault()
    
    if (!selectedImage){
      setImageError("Please upload payment confirmation")
      return
    }

    // if (confirmationType == "Number" && document.getElementById("confirmationNumber").value == ""){
    //   setImageError("Please enter payment confirmation number")
    //   return
    // }
    // if (!confirmationType){
    //   setImageError("Select a payment type to continue")
    //   return
    // }

    const available = await checkAvailableBooths(bookEvent?._id)
    
    if (available){

      const options = {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit"
      }

      const date = new Date()
      const payDateUnformatted = new Date(e.target[6].value)

      const bookingInfo = {
        bookBy: authState,
        bookDate: date.toLocaleDateString("en-US", options),
        bookEvent: bookEvent,
        payMethod: payMethod,
        payAmount: payAmount,
        payDate: payDateUnformatted.toLocaleDateString("en-US", options),
        payConfirmation: imageBinary,
        vendorNotes: e.target[10].value,
        boothQty: boothQty
      }
      setLoading(true)
      await submitRequest(bookingInfo)
      setLoading(false)
    }

  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  const closeModal = () => {
    setBookingModal(false)
    sleep(300).then(() => { 
      setError("")
      setSuccess(false)
      setSelectedImage(null)
      setImageUrl(null)
      setBookEvent(null)
    });

    // setConfirmationType(null)
  }

  return (
    <>
    <Typography sx={{fontSize: "30px",  textAlign:"center", color:"#d9534f"}}>
      {bookEvent?.name.replace("ZN Fashions ", "")}
    </Typography>

    <Typography sx={{fontSize: "20px",  textAlign:"center"}}>
      {bookEvent?.date}
    </Typography>


    <hr style={{margin: "15px 0 15px 0"}} />

    <Typography sx={{fontSize: "15px",  textAlign:"left", marginBottom:"15px"}}>
      Please only submit request <strong style={{color:"red"}}>AFTER</strong> you have made payment to ZN Fashions through one of the below
      Payment Methods. There must only be <strong style={{color:"red"}}>ONE TRANSACTION PER EVENT</strong>, we do not accept lump sum payments for multiple events.
    </Typography>

    <hr style={{margin: "15px 0 15px 0"}} />
    
    <form onSubmit={handleSubmit}>

    {error && 
      <Typography sx={{color: "red", marginBottom:"15px", fontSize:"15px", textAlign:"center"}}>
        {error}
      </Typography>
    }

      <div className="container">

        <div className="row g-2">
          <div className="col-8">

            <FormControl fullWidth>
              <InputLabel id="boothQty">Payment Method</InputLabel>

              <Select 
                required
                name = "payMethod" 
                fullWidth variant="outlined" label="Payment Method" sx={fieldStyling}
                value={payMethod}
                onChange={handleChangePayMethod}
              >
                <MenuItem value = "Zelle">Zelle</MenuItem>
                <MenuItem value = "Venmo">Venmo</MenuItem>
                <MenuItem value = "PayPal">PayPal</MenuItem>
                <MenuItem value = "Direct Deposit">Direct Deposit</MenuItem>
                <MenuItem value = "Cash">Cash</MenuItem>
              
              </Select>
            </FormControl>
          </div>

          <div className="col-4">
            <FormControl fullWidth>
              <InputLabel id="boothQty">Booths</InputLabel>

              <Select 
                required
                name = "boothQty" 
                fullWidth variant="outlined" label="Booths" sx={fieldStyling}
                value={boothQty}
                onChange={handleChangeBoothQty}
              >
                <MenuItem value = {1}>1</MenuItem>
                <MenuItem value = {2}>2</MenuItem>
                <MenuItem value = {3}>3</MenuItem>
                <MenuItem value = {4}>4</MenuItem>
                <MenuItem value = {5}>5</MenuItem>
              
              </Select>
            </FormControl>

          </div>
        </div>

        <div className="row g-2">
          <div className="col-6">
            <FormControl fullWidth>
              <InputLabel id="paymentAmount">Paid Amount</InputLabel>

              <Select 
                required
                name = "payAmount" 
                fullWidth variant="outlined" label="Payment Amount" sx={fieldStyling}
                value={payAmount}
                onChange={handleChangePayAmount}
              >
                <MenuItem value = {225 * boothQty}>{"$" + 225 * boothQty}</MenuItem>
                <MenuItem value = {250 * boothQty}>{"$" + 250 * boothQty}</MenuItem>
                <MenuItem value = {275 * boothQty}>{"$" + 275 * boothQty}</MenuItem>
                <MenuItem value = {300 * boothQty}>{"$" + 300 * boothQty}</MenuItem>
                <MenuItem value = {325 * boothQty}>{"$" + 325 * boothQty}</MenuItem>
                <MenuItem value = {350 * boothQty}>{"$" + 350 * boothQty}</MenuItem>
                <MenuItem value = {375 * boothQty}>{"$" + 375 * boothQty}</MenuItem>
                <MenuItem value = {400 * boothQty}>{"$" + 400 * boothQty}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-6">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker 
                label="Pay Date" 
              />
            </LocalizationProvider>
          </div>
        </div>

        <div className="row g-2 mb-2">
          {imageError && 
            <Typography sx={{color:"red", fontSize:"14px", textAlign:"center"}}>
              {imageError}
            </Typography>
          }

          {/* {confirmationType == "Picture" &&  */}
            <div className="col">
              <Button variant="text" component="label" fullWidth sx={{textTransform: "none", color:"#d9534f", marginBottom:"8px"}}>
                <PhotoCamera sx={{marginRight: "5px"}}/> 
                {(imageUrl && selectedImage) ? "Reselect Proof of Payment" : "Upload Proof of Payment"}
                <input 
                  hidden
                  name="confirmationImage" 
                  accept="image/*" 
                  type="file" 
                  onChange={e => setSelectedImage(e.target.files[0])}
                />
              </Button>
              {imageUrl && selectedImage && (
                <Box textAlign="center">
                  <img src={imageUrl} alt={selectedImage.name} height="112px" style={{margin:"10px 0 15px 0"}}/>
                </Box>
              )}
            </div>
          {/* } */}

          {/* {confirmationType == "Number" && 
            <div className="col">
              <TextField
                id='confirmationNumber'
                name="confirmationNumber"
                label='Confirmation Number'
                fullWidth
              />
            </div>
          } */}
          
        </div>

        <div className="row">
          <div className="col">
            <TextField 
              fullWidth
              multiline
              rows={3}
              label="Booking Notes (Optional)"
              sx={{marginBottom: "20px"}}
            />
          </div>
        </div>

        <div className="row g-2">
          <div className="col">
            <Button 
              type="submit" 
              size="large"
              variant="contained" 
              fullWidth
              sx={{textTransform: "none", backgroundColor:"#d9534f", color: "white", '&:hover': {backgroundColor: "#d44a46"}}}
            >
              Submit Request
            </Button>
          </div>
        </div>

        <div className="row g-2">
          <div className="col">
            <Button 
              variant="outlined" 
              size="large"
              onClick={closeModal}
              fullWidth
              sx={{textTransform: "none", marginTop: "15px", color: "#d44a46", borderColor: "#d44a46", '&:hover': {backgroundColor: "#d44a46", borderColor:"#d44a46", color:"white"}}}
            >
              Cancel
            </Button>
          </div>

        </div>

      </div>

    </form>
  </>
  )
}

export default BookingForm