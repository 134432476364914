import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import {
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { useEffect } from 'react';

import { generateRows } from './demo-data/generator';
import useUsersContext from '../../../hooks/contextHooks/useUsersContext';
import useStyleContext from '../../../hooks/contextHooks/useStyleContext';


export default () => {

  const {headingStyle} = useStyleContext()
  
  const {fetchUsers, users} = useUsersContext()
  useEffect(() => {
    fetchUsers()
  }, [])

  const [columns] = useState([
    { name: 'fName', title: 'First Name' },
    { name: 'lName', title: 'Last Name' },
    { name: 'companyName', title: 'Company' },
    { name: 'email', title: 'Email' },
    { name: 'city', title: 'City' },
    { name: 'state', title: 'State' },
    { name: 'phone', title: 'Phone' },
  ]);

  const [rows] = useState(generateRows({ length: 8 }));
  const [searchValue, setSearchState] = useState('');

  return (
    <Paper>
      <Grid
        rows={users ? users : []}
        columns={columns}
      >
        <SearchState
          value={searchValue}
          onValueChange={setSearchState}
        />
        <IntegratedFiltering />
        <Table />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel/>
      </Grid>
    </Paper>
  );
};
