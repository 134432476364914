import { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

const useModalContext = () => {

  const context = useContext(ModalContext)

  if (!context){
    throw Error("make sure component is wrapped inside the AuthContextProvider")
  }

  return context

}

export default useModalContext