import { Modal, Box, Typography, TextField } from "@mui/material"
import ModalCloseButton from "../../../../components/ModalCloseButton";
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

import useModalContext from "../../../../hooks/contextHooks/useModalContext";
import useStyleContext from "../../../../hooks/contextHooks/useStyleContext";
import ModalPrimaryButton from "../../../../components/ModalPrimaryButton";
import useDenyRequest from "../../../../hooks/otherHooks/booking-hooks/useDenyRequest";
import Loading from "../../../../components/Loading";

const DenyBookingModal = (props) => {

  const {modalStyle, primaryColor} = useStyleContext()
  const {denyBookingModal, setDenyBookingModal, loading, setLoading} = useModalContext()
  const {denyRequest, success, error, setError, setSuccess} = useDenyRequest()

  const closeModal = () => {
    setDenyBookingModal(false)
    setError(null)
    setSuccess(null)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const denyNotes = e.target[0].value
    setLoading(true)
    await denyRequest(props.booking, denyNotes)
    setLoading(false)
  }

  return (

    <Modal
      open={denyBookingModal}
      onClose={closeModal}
    >
      <Box sx={modalStyle}>

      {loading ? <Loading loadingText = "Denying Request..." /> : 
      
        success ? 
          <Box textAlign="center">
            <h4>Successfully Denied Booking Request!</h4>
            <CheckBoxRoundedIcon sx={{color: "green", fontSize: "200px", marginBottom: "10px"}} />
            <ModalCloseButton
              onClick = {closeModal}
            />
          </Box>
        : 
          <>
            <Typography sx={{fontSize: "25px", color: primaryColor}}>
              Are you sure you want to DENY this booking?
            </Typography>

            <hr />

            {error && 
              <Typography sx={{color: "red", marginBottom:"15px", fontSize:"15px", textAlign:"center"}}>
                {error}
              </Typography>
            }
            

            <Typography sx={{marginBottom: "15px"}}>
              The vendor will be sent a denial email with the below reason once the "Deny" button is clicked.
            </Typography>

            <form onSubmit = {handleSubmit}>

              <TextField 
                fullWidth
                multiline
                rows={4}
                label="Denial Reason"
                sx={{marginBottom: "10px"}}
                required
              />

              <ModalPrimaryButton
                type = "submit"
                buttonText = "Deny"
              />

            </form>
            

            <ModalCloseButton
              onClick = {closeModal}
            /> 
          </>
      
        }
        
      </Box>
    </Modal>
  )

}

export default DenyBookingModal