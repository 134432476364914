import { useContext } from "react";
import { EventsContext } from "../../context/EventsContext";

const useEventsContext = (props) => {

  const context = useContext(EventsContext)

  if (!context){
    throw Error("make sure component is wrapped inside the EventsContextProvider")
  }

  return context
}

export default useEventsContext