import { useState } from "react"
import useAuthContext from "../contextHooks/useAuthContext"
import jwtDecode from "jwt-decode"
import { useNavigate } from "react-router-dom"

const useLogin = () => {

  const {authDispatch} = useAuthContext()
  const navigate = useNavigate()

  const [error, setError] = useState()

  const login = async (loginInfo) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/login", {
      method: "POST",
      body: JSON.stringify(loginInfo),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await response.json()
    if (data.status == 200) {
      const decoded = jwtDecode(data.token)
      const role = decoded.role
      localStorage.setItem("user", JSON.stringify(data))
      authDispatch({type:"LOGIN", payload:{role, ...data}})
      if (role == "vendor") {
        navigate("/vendorportal")
      } else if (role == "admin") {
        navigate("/admin")
      }
    } else {
      setError(data.error)
    }
  }

  return {login, error, setError}
}

export default useLogin