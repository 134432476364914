import { useState } from "react"
import useAuthContext from "../../contextHooks/useAuthContext"
import useUsersContext from "../../contextHooks/useUsersContext"

const useUpdateUser = () => {

  const {authState} = useAuthContext()
  const {setUsers} = useUsersContext()

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState("")

  const updateUser = async (update) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/updateuser", {
      method: "POST",
      body: JSON.stringify(update),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authState.token}`
      }
    })

    const data = await response.json()
    console.log(data)
    // if (data.status === 200){
    //   setSuccess(true)
    //   setUsers(data.users)
    // } else {
    //   setError(data.error)
    // }
  }

  return {updateUser, error, setError, success, setSuccess}
}

export default useUpdateUser