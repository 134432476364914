import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography, Button, Tooltip, Slide, Box, Grow } from '@mui/material';
import useStyleContext from "../../../../hooks/contextHooks/useStyleContext"
import DeniedDetailsModal from '../modals/DeniedDetailsModal';
import { useState } from 'react';
import useModalContext from '../../../../hooks/contextHooks/useModalContext';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Loading from '../../../../components/Loading';

const DeniedEvents = (props) => {

  const {primaryColor, headingStyle, rowStyle} = useStyleContext()
  const {setDeniedDetailsModal} = useModalContext()

  const [deniedRequest, setDeniedRequest] = useState()

  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit"
  }

  const convertDate = (date) => {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + 1)
    const convertedDate = newDate.toLocaleDateString("en-US", options)
    return convertedDate
  }

  const openDetailsModal = (request) => {
    setDeniedRequest(request)
    setDeniedDetailsModal(true)
  }


  const toolTipText1 = "Denied Requests are requests you submitted that we had to deny. You will be sent an email with a Denial Reason per denial."
  const toolTipText2 = "Great! Nothing for you to do on this page just yet."


  return (
    <>
      <DeniedDetailsModal 
        request = {deniedRequest}
      />


      <Typography sx={{fontSize: "20px",  marginBottom: "10px", textAlign:"left"}}>
        Denied Requests

        <Tooltip title={toolTipText1} enterTouchDelay={0}>
          <HelpOutlineIcon sx={{color:"gray", margin:"0 0 1px 2px", fontSize:"19px"}}/>
        </Tooltip>

      </Typography>
      <hr />

      {!props.requests ? <Loading loadingText = "Loading your denied events..."/> : ( 
        <>
          {props.requests?.filter(request => request.payStatus == "Denied").length == 0 &&

            <div style={{textAlign:"center", margin:"10px"}}>
              <Typography fontSize="13px">
                <span style={{color:primaryColor, fontSize:"25px", fontWeight:"bold"}}>
                  No denied requests!
                </span>
                <br />
                <Tooltip title={toolTipText2} enterTouchDelay={0}>
                  <HelpOutlineIcon sx={{color:"gray", margin:"0 0 20px 0", fontSize:"17px"}}/>
                </Tooltip>
              </Typography>
              <Typography fontSize="13px">
                For questions, please email: <span style={{color:primaryColor, fontWeight:"bold"}}>booking@znfashions.com.</span>
              </Typography>
            </div>

          }
          <TableContainer component={Paper} sx={{maxHeight: "500px"}}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow sx={{backgroundColor: primaryColor}}>
                <TableCell sx={headingStyle}>Event Name</TableCell>
                <TableCell sx={headingStyle}>Event Date</TableCell>
                <TableCell sx={headingStyle} align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {props.requests?.map(request => {
                if (request.payStatus == "Denied") {
                  return (
                    <TableRow
                    key={request._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={rowStyle}>
                      {request.eventName.replace("ZN Fashions ", "")}
                    </TableCell>
                    <TableCell sx={rowStyle}>{convertDate(request.sortDate)}</TableCell>
                    <TableCell align="center" sx={rowStyle}>
                      <Button variant='text' size='small' sx={{color:"gray", textTransform:"none", fontSize:"12px"}} onClick={() => openDetailsModal(request)}>
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                  )
                }
              })}
            </TableBody>
          </Table>
          </TableContainer>
        
        </>
      )}

      

    
    </>
  )
}

export default DeniedEvents