import { Modal, Backdrop, Box, Fade } from "@mui/material"
import useModalContext from "../hooks/contextHooks/useModalContext"
import useStyleContext from "../hooks/contextHooks/useStyleContext"

const CustomModal = (props) => {

  const {modalStyle} = useStyleContext()

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 300,
          sx: {opacity:".25",background: 'rgba(255,255,255,0.2)', backdropFilter:"blur(2px)"},
        },
      }}
    > 
      <Fade in={props.open} mountOnEnter unmountOnExit {...{ timeout: 300 }}>
        {props.children}
      </Fade>
    </Modal>
  )
}

export default CustomModal