import { useState } from "react"
import useAuthContext from "../../contextHooks/useAuthContext"
import useEventsContext from "../../contextHooks/useEventsContext"

const useUpdateEvent = () => {

  const {authState} = useAuthContext()
  const {setSingleEvent} = useEventsContext()

  const [success, setSuccess] = useState()
  const [error, setError] = useState()

  const updateEvent = async (event) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/updateevent", {
      method: "POST",
      body: JSON.stringify(event),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authState.token}`
      }
    })
    const data = await response.json()
    if (data.status === 200){
      setSuccess(true)
      setSingleEvent(data.updatedEvent)
    } else {
      setError(data.error)
    }
  }

  return {updateEvent, error, success, setError, setSuccess}
}

export default useUpdateEvent