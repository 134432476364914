import { useState } from "react"
import useEventsContext from "../../contextHooks/useEventsContext"
import useAuthContext from "../../contextHooks/useAuthContext"

const useAddEvent = (props) => {

  const {setEventDetails} = useEventsContext()
  const {authState} = useAuthContext()

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const addEvent = async (eventInfo) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/addevent", {
      method: "POST",
      body: JSON.stringify(eventInfo),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authState.token}`
      }
    })
    const data = await response.json()
    if (data.status === 200){
      setSuccess(true)
      setEventDetails(data.filteredEvents)
    } else {
      setError(data.error)
    }
  }

  return {addEvent, success, setSuccess, error}
}

export default useAddEvent