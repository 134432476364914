import { createContext, useState } from "react";
import useAuthContext from "../hooks/contextHooks/useAuthContext";

export const UsersContext = createContext()

export const UsersContextProvider = (props) => {

  const {authState} = useAuthContext()
  const [users, setUsers] = useState()

  const fetchUsers = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/api/getusers", {
      headers: {
        "Authorization": `Bearer ${authState.token}`
      }
    })
    const data = await response.json()
    setUsers(data)
  }

  return(
    <UsersContext.Provider
      value = {{
        fetchUsers,
        setUsers,
        users
      }}
    >
      {props.children}
    </UsersContext.Provider>
  )
}